import "./style.scss";

export const LargeDesktop = ({ children }) => {
  return <div className="large-desktop">{children}</div>;
};

export const Desktop = ({ children }) => {
  return <div className="desktop">{children}</div>;
};
export const Tablet = ({ children }) => {
  return <div className="tablet">{children}</div>;
};
export const Mobile = ({ children }) => {
  return <div className="mobile">{children}</div>;
};
