import { useEffect, useRef, useState } from 'react';
import { setImageAnimate } from '../three';

export const SectionOne = () => {
    const blockRef = useRef();
    const isAnimationFired = useRef(false);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (blockRef.current) {
            window.addEventListener('scroll', () => !isAnimationFired.current && setImageAnimate(blockRef, isAnimationFired.current, setAnimate, 400));
        }
        // return () => window && window.replaceWith(window.cloneNode(true));
    }, [blockRef]);

    return (
        <section className="section-one-wrapper" ref={blockRef}>
            <div className="text desktopV">
                <div className="animated-text-wrapper">
                    <span className={animate ? 'animated-text' : 'animated-text-disabled'}>Experience the finest in bespoke interior</span>
                </div>
                <div className="animated-text-wrapper">
                    <span className={animate ? 'animated-text' : 'animated-text-disabled'} style={{ animationDelay: '.25s' }}>
                        design in a world-class destination
                    </span>
                </div>
                <div className="animated-text-wrapper">
                    <span className={animate ? 'animated-text' : 'animated-text-disabled'} style={{ animationDelay: '.5s' }}>
                        in the heart of London
                    </span>
                </div>
            </div>
            <div className="text mobileV">
                {/* Experience the finest <br />
                in bespoke interior design <br />
                in a world-class destination in the heart of London */}

                <div className="animated-text-wrapper">
                    <span className={animate ? 'animated-text' : 'animated-text-disabled'}>Experience the finest</span>
                </div>
                <div className="animated-text-wrapper">
                    <span className={animate ? 'animated-text' : 'animated-text-disabled'} style={{ animationDelay: '.25s' }}>
                        in bespoke interior design
                    </span>
                </div>
                <div className="animated-text-wrapper">
                    <span className={animate ? 'animated-text' : 'animated-text-disabled'} style={{ animationDelay: '.5s' }}>
                        in a world-class destination
                    </span>
                </div>
                <div className="animated-text-wrapper">
                    <span className={animate ? 'animated-text' : 'animated-text-disabled'} style={{ animationDelay: '.75s' }}>
                        in the heart of London
                    </span>
                </div>
            </div>
        </section>
    );
};
