import { useEffect, useState } from 'react';
import './App.scss';
import Cookies from './pages/cookies';
import { Footer } from './pages/footer';
import { Main } from './pages/main';
import { Preloader } from './pages/preloader';
import Privacy from './pages/privacy';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
    const [policyPage, setPolicyPage] = useState(false);

    useEffect(() => {
        window.history.scrollRestoration = 'manual';
    }, []);

    return (
        <Router>
            <div className="App" style={{ overflow: policyPage ? 'visible' : 'hidden' }}>
                <Preloader />
                <Routes>
                    <Route path="/" element={<Main setPolicyPage={setPolicyPage} />} />
                    <Route path="/privacy" element={<Privacy setPolicyPage={setPolicyPage} />} />
                    <Route path="/cookies" element={<Cookies setPolicyPage={setPolicyPage} />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
