import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'antd';
import { Images } from '../../assets/images';
import { Desktop, LargeDesktop, Mobile, Tablet } from '../../responsive';

export const setImageAnimate = (elem, isCalled, setState, offset, customFunction) => {
    const containerTop = elem.current.getBoundingClientRect().top;
    if (containerTop <= offset && !isCalled) {
        setState(true);
        isCalled = true;
        if (typeof customFunction === 'function') {
            customFunction(); // Execute your custom function here
        }
    }
};

export const SectionThree = ({ onToggle, setTitle }) => {
    const blockRef = useRef();
    const textRef1 = useRef();
    const textRef2 = useRef();
    const textRef3 = useRef();
    const textRef4 = useRef();
    const isAnimationFired = useRef(false);
    const isAnimationTextFired = useRef(false);
    const [animate, setAnimate] = useState(false);
    const image_ref1 = useRef(null);
    const image_ref2 = useRef(null);
    const image_ref11 = useRef(null);
    const image_ref21 = useRef(null);

    const [animateText1, setAnimateText1] = useState(false);
    const [animateText2, setAnimateText2] = useState(false);
    const [animateText3, setAnimateText3] = useState(false);
    const [animateText4, setAnimateText4] = useState(false);
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const delayScale = async () => {
        await delay(100);
        image_ref1.current.style.transform = 'scale(1)';
        image_ref2.current.style.transform = 'scale(1)';
        image_ref11.current.style.transform = 'scale(1)';
        image_ref21.current.style.transform = 'scale(1)';
    };

    const fillFunction = () => {};

    useEffect(() => {
        if (blockRef.current) {
            window.addEventListener('scroll', () => {
                setImageAnimate(blockRef, isAnimationFired.current, setAnimate, 200, delayScale);
                setImageAnimate(textRef1, isAnimationTextFired.current, setAnimateText1, window.innerHeight - 200, fillFunction);
                setImageAnimate(textRef2, isAnimationTextFired.current, setAnimateText2, window.innerHeight - 200, fillFunction);
                setImageAnimate(textRef3, isAnimationTextFired.current, setAnimateText3, window.innerHeight - 200, fillFunction);
                setImageAnimate(textRef4, isAnimationTextFired.current, setAnimateText4, window.innerHeight - 400, fillFunction);
            });
        }
        // return () => window && window.replaceWith(window.cloneNode(true));
    }, [blockRef, textRef1, textRef2, textRef3]);

    return (
        <section className="section-three-wrapper" ref={blockRef}>
            <LargeDesktop>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={24} className="text-col desktop-margin-top">
                        <div className="animated-text-wrapper heading mb32">
                            <span className={animate ? 'animated-text animated-text-black' : 'animated-text-disabled'}>EXPERIENCE THE LUXURY INTERIOR DESIGN</span>
                        </div>
                        <div className="animated-text-wrapper text mb32">
                            <span className={animate ? 'animated-text animated-text-right' : 'animated-text-disabled'}>
                                Welcome to YDF, where we push the boundaries in architecture, interior design, fit-out, and procurement. Our clientele comprises visionaries who aim for nothing less than the exceptional, and that's precisely what we
                                deliver.
                                <br />
                                <br />
                                Our seasoned team of multidisciplinary architects and interior designers with over 13 years of expertise uphold superior quality and outstanding service at every touchpoint of the journey.
                            </span>
                        </div>
                        <div className="highlight-container animated-text-wrapper">
                            <div
                                onClick={() => {
                                    onToggle();
                                    setTitle('EXPERIENCE THE LUXURY INTERIOR DESIGN');
                                }}
                                className={`highlight ${animate ? 'animated-text animated-text-right-brown' : 'animated-text-disabled'}`}>
                                EXPERIENCE WITH US
                            </div>
                        </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24}>
                        <div className="section-three-wrapper-image">
                            <img ref={image_ref1} src={Images.info.largeDesktop[0]} style={{ height: animate ? '540px' : '0%', transform: 'scale(1.15)' }} alt="experience" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={24}>
                        <div className="section-three-wrapper-image">
                            <img ref={image_ref2} src={Images.info.largeDesktop[1]} style={{ height: animate ? '540px' : '0%', transform: 'scale(1.15)' }} alt="experience" />
                        </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} className="text-col desktop-margin-bottom" ref={textRef1}>
                        <div className="animated-text-wrapper heading mb32">
                            <span className={animateText1 ? 'animated-text animated-text-black' : 'animated-text-disabled'}>CURATING TOP-TIER BRANDS</span>
                        </div>
                        <div className="animated-text-wrapper text mb32">
                            <span className={animateText1 ? 'animated-text animated-text-right' : 'animated-text-disabled'}>
                                We've created a unique hub by harmoniously merging over 100 top-tier brands from furnishing, lighting, bathroom solutions, and textiles, all under the influential umbrella of YDF. The result? An unrivaled destination
                                for architects, interior designers, and their discerning clients
                                <br />
                                <br />
                                With a meticulous focus on curating the highest quality materials and furnishings, we ensure that every detail reflects sophistication and elegance.
                            </span>
                        </div>
                        <div className="highlight-container animated-text-wrapper">
                            <div
                                onClick={() => {
                                    onToggle();
                                    setTitle('CURATING TOP-TIER BRANDS');
                                }}
                                className={`highlight ${animateText1 ? 'animated-text animated-text-right-brown' : 'animated-text-disabled'}`}>
                                EXPERIENCE WITH US
                            </div>
                        </div>
                    </Col>
                </Row>
            </LargeDesktop>
            <Desktop>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={24} className="text-col desktop-margin-top">
                        <div className="animated-text-wrapper heading mb32">
                            <span className={animate ? 'animated-text animated-text-black' : 'animated-text-disabled'}>EXPERIENCE THE LUXURY INTERIOR DESIGN</span>
                        </div>
                        <div className="animated-text-wrapper text mb32">
                            <span className={animate ? 'animated-text animated-text-right' : 'animated-text-disabled'}>
                                Welcome to YDF, where we push the boundaries in architecture, interior design, fit-out, and procurement. Our clientele comprises visionaries who aim for nothing less than the exceptional, and that's precisely what we
                                deliver.
                                <br />
                                <br />
                                Our seasoned team of multidisciplinary architects and interior designers with over 13 years of expertise uphold superior quality and outstanding service at every touchpoint of the journey.
                            </span>
                        </div>
                        <div className="highlight-container animated-text-wrapper">
                            <div
                                onClick={() => {
                                    onToggle();
                                    setTitle('EXPERIENCE THE LUXURY INTERIOR DESIGN');
                                }}
                                className={`highlight ${animate ? 'animated-text animated-text-right-brown' : 'animated-text-disabled'}`}>
                                EXPERIENCE WITH US
                            </div>
                        </div>
                    </Col>

                    <Col xl={12} lg={12} md={12} sm={24}>
                        <div className="section-three-wrapper-image">
                            <img ref={image_ref11} src={Images.info.desktop[0]} style={{ height: animate ? '540px' : '0%', transform: 'scale(1.15)' }} alt="experience" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={24}>
                        <div className="section-three-wrapper-image">
                            <img ref={image_ref21} src={Images.info.desktop[1]} style={{ height: animate ? '540px' : '0%', transform: 'scale(1.15)' }} alt="experience" />
                        </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} className="text-col desktop-margin-bottom" ref={textRef2}>
                        <div className="animated-text-wrapper heading mb32">
                            <span className={animateText2 ? 'animated-text animated-text-black' : 'animated-text-disabled'}>CURATING TOP-TIER BRANDS</span>
                        </div>
                        <div className="animated-text-wrapper text mb32">
                            <span className={animateText2 ? 'animated-text animated-text-right' : 'animated-text-disabled'}>
                                We've created a unique hub by harmoniously merging over 100 top-tier brands from furnishing, lighting, bathroom solutions, and textiles, all under the influential umbrella of YDF. The result? An unrivaled destination
                                for architects, interior designers, and their discerning clients
                                <br />
                                <br />
                                With a meticulous focus on curating the highest quality materials and furnishings, we ensure that every detail reflects sophistication and elegance.
                            </span>
                        </div>
                        <div className="highlight-container animated-text-wrapper">
                            <div
                                onClick={() => {
                                    onToggle();
                                    setTitle('CURATING TOP-TIER BRANDS');
                                }}
                                className={`highlight ${animateText2 ? 'animated-text animated-text-right-brown' : 'animated-text-disabled'}`}>
                                EXPERIENCE WITH US
                            </div>
                        </div>
                    </Col>
                </Row>
            </Desktop>
            <Tablet>
                <Row>
                    <Col span={24} className="text-col">
                        <div className="animated-text-wrapper heading mb32">
                            <span className={animate ? 'animated-text animated-text-black' : 'animated-text-disabled'}>EXPERIENCE THE LUXURY INTERIOR DESIGN</span>
                        </div>
                        <div className="animated-text-wrapper text mb32">
                            <span className={animate ? 'animated-text animated-text-right' : 'animated-text-disabled'}>
                                Welcome to YDF, where we push the boundaries in architecture, interior design, fit-out, and procurement. Our clientele comprises visionaries who aim for nothing less than the exceptional, and that's precisely what we
                                deliver.
                                <br />
                                <br />
                                Our seasoned team of multidisciplinary architects and interior designers with over 13 years of expertise uphold superior quality and outstanding service at every touchpoint of the journey.
                            </span>
                        </div>
                        <div className="highlight-container animated-text-wrapper">
                            <div
                                onClick={() => {
                                    onToggle();
                                    setTitle('EXPERIENCE THE LUXURY INTERIOR DESIGN');
                                }}
                                className={`highlight ${animate ? 'animated-text animated-text-right-brown' : 'animated-text-disabled'}`}>
                                EXPERIENCE WITH US
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="section-three-wrapper-image">
                            <img src={Images.info.tablet[0]} style={{ height: animate ? '540px' : '0%', transform: !animate ? 'scale(1.1)' : 'scale(1)' }} alt="experience" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className="text-col text-col-left" ref={textRef3}>
                        <div className="animated-text-wrapper heading mb32">
                            <span className={animateText3 ? 'animated-text animated-text-black' : 'animated-text-disabled'}>CURATING TOP-TIER BRANDS</span>
                        </div>
                        <div className="animated-text-wrapper text mb32">
                            <span className={animateText3 ? 'animated-text animated-text-right' : 'animated-text-disabled'}>
                                We've created a unique hub by harmoniously merging over 100 top-tier brands from furnishing, lighting, bathroom solutions, and textiles, all under the influential umbrella of YDF. The result? An unrivaled destination
                                for architects, interior designers, and their discerning clients
                                <br />
                                <br />
                                With a meticulous focus on curating the highest quality materials and furnishings, we ensure that every detail reflects sophistication and elegance.
                            </span>
                        </div>
                        <div className="highlight-container animated-text-wrapper">
                            <div
                                onClick={() => {
                                    onToggle();
                                    setTitle('CURATING TOP-TIER BRANDS');
                                }}
                                className={`highlight ${animateText3 ? 'animated-text animated-text-right-brown' : 'animated-text-disabled'}`}>
                                EXPERIENCE WITH US
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="section-three-wrapper-image">
                            <img src={Images.info.tablet[1]} style={{ height: animate ? '540px' : '0%', transform: !animate ? 'scale(1.1)' : 'scale(1)' }} alt="experience" />
                        </div>
                    </Col>
                </Row>
            </Tablet>
            <Mobile>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={24} className="text-col">
                        <div className="animated-text-wrapper heading mb32">
                            <span className={animate ? 'animated-text animated-text-black' : 'animated-text-disabled'}>EXPERIENCE THE LUXURY INTERIOR DESIGN</span>
                        </div>
                        <div className="animated-text-wrapper text mb32">
                            <span className={animate ? 'animated-text animated-text-right' : 'animated-text-disabled'}>
                                Welcome to YDF, where we push the boundaries in architecture, interior design, fit-out, and procurement. Our clientele comprises visionaries who aim for nothing less than the exceptional, and that's precisely what we
                                deliver.
                                <br />
                                <br />
                                Our seasoned team of multidisciplinary architects and interior designers with over 13 years of expertise uphold superior quality and outstanding service at every touchpoint of the journey.
                            </span>
                        </div>
                        <div className="highlight-container animated-text-wrapper">
                            <div
                                onClick={() => {
                                    onToggle();
                                    setTitle('EXPERIENCE THE LUXURY INTERIOR DESIGN');
                                }}
                                className={`highlight ${animate ? 'animated-text animated-text-right-brown' : 'animated-text-disabled'}`}>
                                EXPERIENCE WITH US
                            </div>
                        </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24}>
                        <div className="section-three-wrapper-image">
                            <img src={Images.info.phone[0]} style={{ height: '290px' }} alt="experience" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={24} className="text-col" ref={textRef4}>
                        <div className="animated-text-wrapper heading mb32">
                            <span className={animateText4 ? 'animated-text animated-text-black' : 'animated-text-disabled'}>CURATING TOP-TIER BRANDS</span>
                        </div>
                        <div className="animated-text-wrapper text mb32">
                            <span className={animateText4 ? 'animated-text animated-text-right' : 'animated-text-disabled'}>
                                We've created a unique hub by harmoniously merging over 100 top-tier brands from furnishing, lighting, bathroom solutions, and textiles, all under the influential umbrella of YDF. The result? An unrivaled destination
                                for architects, interior designers, and their discerning clients
                                <br />
                                <br />
                                With a meticulous focus on curating the highest quality materials and furnishings, we ensure that every detail reflects sophistication and elegance.
                            </span>
                        </div>
                        <div className="highlight-container animated-text-wrapper">
                            <div
                                onClick={() => {
                                    onToggle();
                                    setTitle('CURATING TOP-TIER BRANDS');
                                }}
                                className={`highlight ${animateText4 ? 'animated-text animated-text-right-brown' : 'animated-text-disabled'}`}>
                                EXPERIENCE WITH US
                            </div>
                        </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24}>
                        <div className="section-three-wrapper-image">
                            <img src={Images.info.phone[1]} style={{ height: '290px' }} alt="experience" />
                        </div>
                    </Col>
                </Row>
            </Mobile>
        </section>
    );
};
