import { useEffect, useRef, useState } from 'react';
import { Input } from '../../components/input';
import { SolidButton } from '../../components/button';
import { message } from 'antd';
import { setImageAnimate } from '../three';

const host = 'https://ydf-strapi-9534c0d766db.herokuapp.com/';

export const SectionFour = () => {
    const blockRef = useRef();
    const isAnimationFired = useRef(false);

    const [email, setEmail] = useState();
    const [animate, setAnimate] = useState(false);
    const [emailCorrect, setEmailCorrect] = useState(true);

    useEffect(() => {
        if (blockRef.current) {
            window.addEventListener('scroll', () => !isAnimationFired.current && setImageAnimate(blockRef, isAnimationFired.current, setAnimate, 200));
        }
    }, [blockRef]);

    const onSubmit = async () => {
        const data = {
            email,
        };

        fetch(`${host}api/enquiries`, {
            method: 'POST',
            body: JSON.stringify({
                data,
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => response.json())
            .then(() => {
                message.success('Enquiry sent successfully.');
                setEmail('');
            });
    };

    function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(email)) {
            onSubmit();
            setEmailCorrect(true);
        } else {
            setEmailCorrect(false);
        }
    }

    return (
        <section className="section-four-wrapper" ref={blockRef}>
            <div className="text desktopV">
                <div className="text">
                    <div className="animated-text-wrapper">
                        <span className={animate ? 'animated-text' : 'animated-text-disabled-gray'}>Find out more about our opening</span>
                    </div>
                    <div className="animated-text-wrapper">
                        <span className={animate ? 'animated-text' : 'animated-text-disabled-gray'} style={{ animationDelay: '.25s' }}>
                            at 70, Brompton Rd
                        </span>
                    </div>
                </div>
            </div>
            <div className="text mobileV">
                <div className="text">
                    <div className="animated-text-wrapper">
                        <span className={animate ? 'animated-text' : 'animated-text-disabled-gray'} style={{width: "320px"}}>Find out more about </span>
                    </div>
                    <div className="animated-text-wrapper">
                        <span className={animate ? 'animated-text' : 'animated-text-disabled-gray'} style={{ animationDelay: '.25s', width: "320px" }}>
                            our opening
                        </span>
                    </div>
                    <div className="animated-text-wrapper">
                        <span className={animate ? 'animated-text' : 'animated-text-disabled-gray'} style={{ animationDelay: '.5s', width: "320px" }}>
                            at 70, Brompton Rd
                        </span>
                    </div>
                </div>
            </div>
            <div className="action">
                <div>
                    <Input value={email} onChnage={setEmail} text="Email address" />
                    {!emailCorrect && <p style={{ color: 'red', fontFamily: 'Montserrat', marginTop: '5px', transition: '0.3s' }}>You need to enter correct email.</p>}
                </div>
                <SolidButton text="notify" onClick={() => validateEmail(email)} width={250} height={45} extraPadding={true} />
            </div>
        </section>
    );
};
