import React, { useEffect, useRef, useState } from 'react';
import { Images } from '../../assets/images';
import { Desktop, LargeDesktop, Mobile, Tablet } from '../../responsive';
import { setImageAnimate } from '../three';

export const SectionTwo = () => {
    const blockRef1 = useRef();
    const blockRef2 = useRef();
    const blockRef3 = useRef();
    const blockRef12 = useRef();
    const blockRef22 = useRef();
    const blockRef32 = useRef();
    const blockRef13 = useRef();
    const blockRef23 = useRef();
    const blockRef33 = useRef();
    const isAnimationFired = useRef(false);
    const [animate1, setAnimate1] = useState(false);
    const [animate2, setAnimate2] = useState(false);
    const [animate3, setAnimate3] = useState(false);
    const [animate12, setAnimate12] = useState(false);
    const [animate22, setAnimate22] = useState(false);
    const [animate32, setAnimate32] = useState(false);
    const [animate13, setAnimate13] = useState(false);
    const [animate23, setAnimate23] = useState(false);
    const [animate33, setAnimate33] = useState(false);

    useEffect(() => {
        if (blockRef1.current && blockRef2.current && blockRef3.current) {
            window.addEventListener('scroll', () => {
                setImageAnimate(blockRef1, false, setAnimate1, 100);
                setImageAnimate(blockRef2, false, setAnimate2, 100);
                setImageAnimate(blockRef3, false, setAnimate3, 100);
                setImageAnimate(blockRef12, false, setAnimate12, 100);
                setImageAnimate(blockRef22, false, setAnimate22, 100);
                setImageAnimate(blockRef32, false, setAnimate32, 100);
                setImageAnimate(blockRef13, false, setAnimate13, 100);
                setImageAnimate(blockRef23, false, setAnimate23, 100);
                setImageAnimate(blockRef33, false, setAnimate33, 100);
            });
        }
        // return () => window && window.replaceWith(window.cloneNode(true));
    }, [blockRef1, blockRef2, blockRef3]);

    return (
        <>
            <LargeDesktop>
                {Images.slider.largeDesktop.map((src, i) => (
                    <section key={src} className="block block-statement container-fluid flex-center has-image " style={{ ['--aspect-ratio']: '4/2' }} ref={eval(`blockRef${i + 1}`)}>
                        <div className="bg-wrap">
                            <img className="bg" src={src} alt="slider" style={{ transform: !eval(`animate${i + 1}`) ? 'scale(1.15)' : 'scale(1)', transition: 'all 0.3s ease-out' }} />
                        </div>
                    </section>
                ))}
            </LargeDesktop>
            <Desktop>
                {Images.slider.desktop.map((src, i) => (
                    <section key={src} className="block block-statement container-fluid flex-center has-image " style={{ ['--aspect-ratio']: '4/2' }} ref={eval(`blockRef${i + 1}2`)}>
                        <div className="bg-wrap">
                            <img className="bg" src={src} alt="slider" style={{ transform: !eval(`animate${i + 1}2`) ? 'scale(1.15)' : 'scale(1)', transition: 'all 0.3s ease-out' }} />
                        </div>
                    </section>
                ))}
            </Desktop>
            <Tablet>
                {Images.slider.tablet.map((src, i) => (
                    <section key={src} className="block block-statement container-fluid flex-center has-image " style={{ ['--aspect-ratio']: '4/2' }} ref={eval(`blockRef${i + 1}3`)}>
                        <div className="bg-wrap">
                            <img className="bg" src={src} alt="slider" style={{ transform: !eval(`animate${i + 1}3`) ? 'scale(1.15)' : 'scale(1)', transition: 'all 0.3s ease-out' }} />
                        </div>
                    </section>
                ))}
            </Tablet>
            <Mobile>
                {Images.slider.phone.map((src, index) => (
                    <section key={src} className="block block-statement container-fluid flex-center has-image " style={{ ['--aspect-ratio']: '4/2' }}>
                        <div className="bg-wrap">
                            <img className="bg" src={src} alt="slider" style={{ objectPosition: `${index == 1 ? '30% 50%' : 'bottom'}` }} />
                        </div>
                    </section>
                ))}
            </Mobile>
        </>
    );
};
