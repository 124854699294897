import React from 'react';
import './style.scss';

export const Preloader = () => {
    return (
        <div className="preloader-container">
            <div className="progress-bar"></div>
            <svg width="135" height="61" viewBox="0 0 135 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M59.652 31.3478V10.0264C59.652 7.48657 61.162 6.06381 63.9208 6.06381H74.7344C81.893 6.06381 87.8255 11.6243 87.9568 18.4758C88.0218 21.7163 86.7525 24.8244 84.3882 27.2324C81.8272 29.8377 78.2369 31.3478 74.5158 31.3478H59.652ZM94.3706 18.7166C94.3706 8.40655 85.5706 -2.92448e-05 74.7567 -2.92448e-05H53.2599V37.4333H74.7344C85.5489 37.4333 94.3706 29.0273 94.3706 18.7166Z" fill="#71311A"/>
                <path d="M31.413 16.5713L47.1524 0.240737H38.2434L23.6197 15.4989L8.90955 0.240737H0L15.8049 16.5713C16.0897 16.8778 16.3523 17.1404 16.6148 17.4029C19.0667 19.9204 20.4022 21.2997 20.4022 27.035V37.2798H26.8158V27.0567C26.8158 21.387 28.2387 19.8987 30.6253 17.4252C30.8662 17.1404 31.1283 16.8778 31.413 16.5713Z" fill="#71311A"/>
                <path d="M112.474 21.7595H128.936V15.718H108.687V9.87255C108.687 7.26774 109.759 6.26104 112.54 6.26104H135V0.241035H102.361V37.2803H108.687V25.5246C108.687 22.6789 109.979 21.7595 112.474 21.7595Z" fill="#71311A"/>
                <path d="M15.1968 60.2173H13.681V51.1976H15.1968V60.2173Z" fill="#71311A"/>
                <path d="M29.9766 51.1976V60.2173H27.867L24.0771 53.3705H23.8245V60.2173H22.3843V51.1976H24.4941L28.2838 58.0444H28.5364V51.1976H29.9766Z" fill="#71311A"/>
                <path d="M43.4927 52.5366H40.6126V60.2173H39.0967V52.5366H36.2037V51.1976H43.4927V52.5366Z" fill="#71311A"/>
                <path d="M56.3775 58.8402V60.2173H49.7327V51.1976H56.2133V52.5744H51.2486V54.899H55.1775V56.2507H51.2486V58.8402H56.3775Z" fill="#71311A"/>
                <path d="M66.7862 52.5366H64.4998V55.2905H66.7862C68.138 55.2905 68.6307 54.8231 68.6307 53.9137C68.6307 53.0041 68.138 52.5366 66.7862 52.5366ZM68.7065 56.3265L70.2097 60.2173H68.6181L67.2284 56.6296H64.4998V60.2173H62.9839V51.1976H67.1022C69.0475 51.1976 70.1718 52.2334 70.1718 53.9137C70.1718 55.063 69.6539 55.9095 68.7065 56.3265Z" fill="#71311A"/>
                <path d="M78.5594 60.2173H77.0436V51.1976H78.5594V60.2173Z" fill="#71311A"/>
                <path d="M92.366 55.7075C92.366 53.9261 91.5197 52.4103 89.6122 52.4103C87.6921 52.4103 86.8456 53.9261 86.8456 55.7075C86.8456 57.4885 87.6921 59.0046 89.6122 59.0046C91.5197 59.0046 92.366 57.4885 92.366 55.7075ZM93.9075 55.7075C93.9075 58.3349 92.404 60.3816 89.6122 60.3816C86.8078 60.3816 85.3046 58.3349 85.3046 55.7075C85.3046 53.08 86.8078 51.0333 89.6122 51.0333C92.404 51.0333 93.9075 53.08 93.9075 55.7075Z" fill="#71311A"/>
                <path d="M104.392 52.5366H102.105V55.2905H104.392C105.743 55.2905 106.236 54.8231 106.236 53.9137C106.236 53.0041 105.743 52.5366 104.392 52.5366ZM106.312 56.3265L107.815 60.2173H106.224L104.834 56.6296H102.105V60.2173H100.589V51.1976H104.708C106.653 51.1976 107.777 52.2334 107.777 53.9137C107.777 55.063 107.259 55.9095 106.312 56.3265Z" fill="#71311A"/>
                <path d="M118.856 55.038C120.283 55.2906 121.319 56.074 121.319 57.6275C121.319 59.0805 120.131 60.3816 117.807 60.3816C115.622 60.3816 114.245 59.2572 114.068 57.5771H115.596C115.786 58.6635 116.733 59.055 117.845 59.055C119.007 59.055 119.79 58.5246 119.79 57.6655C119.79 56.9203 119.247 56.5286 118.451 56.3897L116.746 56.0991C115.356 55.8592 114.422 55.0507 114.422 53.661C114.422 52.221 115.571 51.0333 117.744 51.0333C119.639 51.0333 120.915 52.044 121.117 53.5727H119.576C119.386 52.7894 118.805 52.3599 117.719 52.3599C116.67 52.3599 115.95 52.8273 115.95 53.6232C115.95 54.3179 116.519 54.621 117.226 54.7474L118.856 55.038Z" fill="#71311A"/>
            </svg>
        </div>
    );
};
