import React from "react";

export const SolidButton = ({ text, onClick, type = "button", width, height, extraPadding = false }) => {
  return (
    <button type="submit" className={`cs-solid-button ${extraPadding ? "extra-padding": ""}`} onClick={onClick} style={{minWidth: `${width}px`, maxHeight: `${height}px`}}>
      {text}
    </button>
  );
};

export const SolidButtonForm = ({ text, onClick, type = "button" }) => {
  return (
    <button type="submit" className="cs-solid-button-form" onClick={onClick}>
      {text}
    </button>
  );
};

export const OutlineButton = ({ text, onClick }) => {
  return (
    <button className="cs-outline-button" onClick={onClick}>
      {text}
    </button>
  );
};
