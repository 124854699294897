import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Checkbox, Col, Modal, Row, message as messages, Form, Input, Upload } from 'antd';
import { SolidButton, SolidButtonForm } from './button';
import { Link } from 'react-router-dom';
const { Dragger } = Upload;

// const host = "http://localhost:1337/";
const Item = Form.Item;
const host = 'https://ydf-strapi-9534c0d766db.herokuapp.com/';

const props = {
    name: 'files',
    multiple: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            // console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            messages.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            messages.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        // console.log('Dropped files', e.dataTransfer.files);
    },
};

export const FormModal = ({ open, onClose, title }) => {
    const [form] = Form.useForm();

    const [fileList, setFileList] = useState([]);
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [message, setMessage] = useState();
    const [policy, setPolicy] = useState();
    const [news, setNews] = useState();

    const reset = () => {
        setEmail('');
        setFirstName('');
        setLastName('');
        setMessage('');
        setPhoneNumber('');
        setNews(false);
        setPolicy(false);
        form.resetFields();
        setFileList([]);
    };

    const onSubmit = async (values) => {
        const formData = new FormData();
        // console.log(values, fileList);

        for (const file of fileList) {
            formData.append('files', file.originFileObj);
        }

        let response = await fetch(`${host}api/upload`, {
            method: 'POST',
            body: formData,
        });
        response = await response.json();

        Object.assign(values, {
            media: [...response.map((data) => data.id)],
        });

        fetch(`${host}api/teams`, {
            method: 'POST',
            body: JSON.stringify({
                data: values,
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => response.json())
            .then(() => {
                messages.success('Info sent successfully.');
                reset();
                onClose();
            })
            .catch((err) => console.log(err));
    };

    const firstNameRef = React.useRef();
    const lastNameRef = React.useRef();
    const emailRef = React.useRef();
    const phoneRef = React.useRef();
    const messageRef = React.useRef();
    const textAreaRefValue = React.useRef();
    // const firstNameRef = React.useRef();

    const span1Ref = React.useRef();
    const span2Ref = React.useRef();
    const span3Ref = React.useRef();
    const span4Ref = React.useRef();
    const span5Ref = React.useRef();
    const span6Ref = React.useRef();
    const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB
    // const firstNameRef = React.useRef();

    const handleFocus = (elem, span) => {
        elem.current.style.fontSize = '11px';
        elem.current.style.letterSpacing = '0.77px';
        span.current.style.background = '#686868';
        span.current.style.opacity = '1';
        span.current.style.width = '100%';
    };

    const handleBlur = (elem, span) => {
        elem.current.style.fontSize = '14px';
        elem.current.style.letterSpacing = '0.98px';
        span.current.style.background = '#C0BEBE';
        span.current.style.opacity = '0';
        span.current.style.width = '0px';
    };

    return (
        <Modal
            footer={null}
            title={null}
            closable={false}
            open={open}
            className="enquire-drawer-wrapper"
            rootClassName="test"
            width={'100%'}
            style={{
                top: 0,
                height: '100%',
                padding: 0,
                width: '100%',
                maxWidth: '959px',
            }}
            onCancel={() => {
                reset();
                onClose();
            }}>
            <div className="enquire-wrapper">
                <div className="close-wrapper">
                    <div
                        className="close"
                        onClick={() => {
                            reset();
                            onClose();
                        }}>
                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25.7272 47.3958C37.6934 47.3958 47.3939 37.6953 47.3939 25.7292C47.3939 13.763 37.6934 4.0625 25.7272 4.0625C13.761 4.0625 4.06055 13.763 4.06055 25.7292C4.06055 37.6953 13.761 47.3958 25.7272 47.3958Z"
                                fill="#804E3B"
                            />
                            <path d="M33.3125 18.6875L18.6875 33.3125" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M18.6875 18.6875L33.3125 33.3125" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
                <div className="heading">{title || 'CREATE WITH US'}</div>
                <div className="text">We are always on the lookout for top talent to join us.</div>
                <Form form={form} layout="vertical" onFinish={onSubmit}>
                    <div className="action-wrapper">
                        <Row gutter={[32]} className="super-width">
                            <Col xl={12} lg={12} md={12} xs={24}>
                                <label for="firstName" ref={firstNameRef}>
                                    First Name
                                </label>
                                <div className="cs-input-2">
                                    <Item rules={[{ required: true, message: 'First name is required.' }]} name={'firstName'} onFocus={() => handleFocus(firstNameRef, span1Ref)} onBlur={() => handleBlur(firstNameRef, span1Ref)}>
                                        <Input />
                                    </Item>
                                    <span className="static-span-form"></span>
                                    <span ref={span1Ref}></span>
                                </div>
                            </Col>
                            <Col xl={12} lg={12} md={12} xs={24}>
                                <label for="lastName" ref={lastNameRef}>
                                    Last Name
                                </label>

                                <div className="cs-input-2">
                                    <Item rules={[{ required: true, message: 'Last name is required.' }]} name={'lastName'} onFocus={() => handleFocus(lastNameRef, span2Ref)} onBlur={() => handleBlur(lastNameRef, span2Ref)}>
                                        <Input />
                                    </Item>
                                    <span className="static-span-form"></span>
                                    <span ref={span2Ref}></span>
                                </div>
                            </Col>
                            <Col xl={12} lg={12} md={12} xs={24}>
                                <label for="email" ref={emailRef}>
                                    Email
                                </label>
                                <div className="cs-input-2">
                                    <Item
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Email is required.',
                                            },
                                            {
                                                type: 'email',
                                                message: 'Email is not valid.',
                                            },
                                        ]}
                                        name={'email'}
                                        onFocus={() => handleFocus(emailRef, span3Ref)}
                                        onBlur={() => handleBlur(emailRef, span3Ref)}>
                                        <Input type="email" onChange={setEmail} />
                                    </Item>
                                    <span className="static-span-form"></span>
                                    <span ref={span3Ref}></span>
                                </div>
                            </Col>
                            <Col xl={12} lg={12} md={12} xs={24}>
                                <label for="email" ref={phoneRef}>
                                    Phone Number
                                </label>
                                <div className="cs-input-2">
                                    <Item rules={[{ required: true, message: 'Phone number is required.' }]} name={'phoneNumber'} onFocus={() => handleFocus(phoneRef, span4Ref)} onBlur={() => handleBlur(phoneRef, span4Ref)}>
                                        <Input />
                                    </Item>
                                    <span className="static-span-form"></span>
                                    <span ref={span4Ref}></span>
                                </div>
                            </Col>
                            <Col xl={24} lg={24} md={24} xs={24}>
                                <label for="message" ref={messageRef}>
                                    Message
                                </label>
                                <div className="cs-input-2">
                                    <Item rules={[{ required: true, message: 'Message is required.' }]} name={'message'} onFocus={() => handleFocus(messageRef, span5Ref)} onBlur={() => handleBlur(messageRef, span5Ref)}>
                                        <Input.TextArea ref={textAreaRefValue} rows={1} autoSize={true} />
                                    </Item>
                                    <span className="static-span-form-textarea"></span>
                                    <span ref={span5Ref} className="static-span-form-textarea-2 bottom-40"></span>
                                </div>
                            </Col>
                            <Col className="tesdasd" span={24}>
                                <Item
                                    name={'files'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Files are required',
                                        },
                                    ]}
                                    valuePropName="checked">
                                    <Dragger
                                        name="files"
                                        onRemove={({ fileList }) => {
                                            setFileList(fileList);
                                        }}
                                        onChange={({ fileList }) => {
                                            setFileList(fileList);
                                        }}
                                        beforeUpload={(file) => {
                                            if (file.size <= MAX_FILE_SIZE) {
                                                setFileList([file]);
                                            } else {
                                                messages.error('File size exceeds the limit.');
                                            }
                                            return false; // Prevent the default upload behavior
                                        }}
                                        customRequest={({ file, onSuccess }) => {
                                            setTimeout(() => {
                                                onSuccess('ok');
                                            }, 0);
                                        }}
                                        fileList={fileList}
                                        accept=".doc,.docx,.txt,.rtf,.odt,.pdf">
                                        <div className="texttt">
                                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M17.3666 9.20835L9.70825 16.8667C8.77005 17.8049 7.49757 18.332 6.17075 18.332C4.84393 18.332 3.57145 17.8049 2.63325 16.8667C1.69505 15.9285 1.16797 14.656 1.16797 13.3292C1.16797 12.0024 1.69505 10.7299 2.63325 9.79168L10.2916 2.13335C10.9171 1.50788 11.7654 1.15649 12.6499 1.15649C13.5345 1.15649 14.3828 1.50788 15.0082 2.13335C15.6337 2.75882 15.9851 3.60713 15.9851 4.49168C15.9851 5.37623 15.6337 6.22455 15.0082 6.85002L7.34158 14.5083C7.02885 14.8211 6.60469 14.9968 6.16242 14.9968C5.72014 14.9968 5.29598 14.8211 4.98325 14.5083C4.67051 14.1956 4.49482 13.7715 4.49482 13.3292C4.49482 12.8869 4.67051 12.4627 4.98325 12.15L12.0582 5.08335"
                                                    stroke="#774F3E"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            Attach a resume file
                                        </div>
                                        <p className="ant-upload-hint">.doc, .docx, .txt, .rtf, .odt, .pdf up to 3mb.</p>
                                    </Dragger>
                                </Item>
                            </Col>
                            <Col span={36}>
                                <Checkbox checked={news} onChange={(e) => setNews(e.target.checked)} className="mont-font">
                                    Join our Newsletter
                                </Checkbox>
                                <br />
                                <div style={{ height: '12px' }}></div>
                                <Item
                                    name={'policy'}
                                    rules={[
                                        {
                                            validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Privacy policy acceptance is required.'))),
                                        },
                                    ]}
                                    valuePropName="checked">
                                    <Checkbox checked={policy} onChange={(e) => setPolicy(e.target.checked)} className="mont-font">
                                        I’ve read and accept the{' '}
                                        <Link
                                            to="/privacy"
                                            className="text-under"
                                            onClick={() => {
                                                reset();
                                                onClose();
                                            }}>
                                            privacy policy
                                        </Link>
                                        .
                                    </Checkbox>
                                </Item>
                            </Col>
                        </Row>
                    </div>
                    <div className="fixed-mobile">
                        <SolidButtonForm type="submit" text="submit" />
                    </div>
                </Form>
            </div>
        </Modal>
    );
};
