export const Images = {
    banner: {
        largeDesktopBanner: '/images/banner/largeDesktop.jpg',
        desktopBanner: '/images/banner/largeDesktop.jpg',
        tablet: '/images/banner/largeDesktop.jpg',
        mobile: '/images/banner/largeDesktop.jpg',
    },
    slider: {
        largeDesktop: ['/images/slider/largeDesktop1.webp', '/images/slider/largeDesktop2.webp', '/images/slider/largeDesktop3.webp'],
        desktop: ['/images/slider/largeDesktop1.webp', '/images/slider/largeDesktop2.webp', '/images/slider/largeDesktop3.webp'],
        tablet: ['/images/slider/largeDesktop1.webp', '/images/slider/largeDesktop2.webp', '/images/slider/largeDesktop3.webp'],
        phone: ['/images/slider/largeDesktop1.webp', '/images/slider/largeDesktop2.webp', '/images/slider/largeDesktop3.webp'],
    },
    info: {
        largeDesktop: ['/images/info/largeDesktop1.webp', '/images/info/largeDesktop2.webp'],
        desktop: ['/images/info/largeDesktop1.webp', '/images/info/largeDesktop2.webp'],
        tablet: ['/images/info/largeDesktop1.webp', '/images/info/largeDesktop2.webp'],
        phone: ['/images/info/largeDesktop1.webp', '/images/info/largeDesktop2.webp'],
    },
    services: {
        largeDesktop: [
            '/images/services/largeDesktop1.webp',
            '/images/services/largeDesktop2.webp',
            '/images/services/largeDesktop3.webp',
            '/images/services/largeDesktop4.webp',
            '/images/services/largeDesktop5.webp',
            '/images/services/largeDesktop6.webp',
        ],
        desktop: ['/images/services/largeDesktop1.webp', '/images/services/largeDesktop2.webp', '/images/services/largeDesktop3.webp', '/images/services/largeDesktop4.webp', '/images/services/largeDesktop5.webp', '/images/services/largeDesktop6.webp'],
        tablet: ['/images/services/largeDesktop1.webp', '/images/services/largeDesktop2.webp', '/images/services/largeDesktop3.webp', '/images/services/largeDesktop4.webp', '/images/services/largeDesktop5.webp', '/images/services/largeDesktop6.webp'],
        phone: ['/images/services/largeDesktop1.webp', '/images/services/largeDesktop2.webp', '/images/services/largeDesktop3.webp', '/images/services/largeDesktop4.webp', '/images/services/largeDesktop5.webp', '/images/services/largeDesktop6.webp'],
    },
    bottomInfo: {
        largeDesktop: '/images/bottomInfo/largeDesktop.webp',
        phone: '/images/bottomInfo/largeDesktop.webp',
        phone1: '/images/bottomInfo/phone1.webp',
    },
    bottomSlider: {
        largeDesktop: ['/images/bottomSlider/largeDesktop1.webp', '/images/bottomSlider/largeDesktop2.webp', '/images/bottomSlider/largeDesktop3.webp', '/images/bottomSlider/largeDesktop4.webp', '/images/bottomSlider/largeDesktop5.webp'],
        desktop: ['/images/bottomSlider/largeDesktop1.webp', '/images/bottomSlider/largeDesktop2.webp', '/images/bottomSlider/largeDesktop3.webp', '/images/bottomSlider/largeDesktop4.webp', '/images/bottomSlider/largeDesktop5.webp'],
        tablet: ['/images/bottomSlider/largeDesktop1.webp', '/images/bottomSlider/largeDesktop2.webp', '/images/bottomSlider/largeDesktop3.webp', '/images/bottomSlider/largeDesktop4.webp', '/images/bottomSlider/largeDesktop5.webp'],
        phone: [
            '/images/bottomSlider/largeDesktop1.webp',
            '/images/bottomSlider/phone2_1.webp',
            '/images/bottomSlider/phone2_2.png',
            '/images/bottomSlider/phone3.webp',
            '/images/bottomSlider/phone4_1.webp',
            '/images/bottomSlider/phone4_2.webp',
            '/images/bottomSlider/phone4_3.webp',
            '/images/bottomSlider/phone4_4.webp',
            '/images/bottomSlider/phone5.webp',
        ],
    },
};
