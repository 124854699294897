import React, { useEffect } from 'react';
import { Hero } from '../../sections/hero';
import Policy from '../../sections/policy';
import useCheckPath from '../../hooks/useCheckPath';

const Privacy = ({ setPolicyPage }) => {
    const policyPage = useCheckPath();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => setPolicyPage(policyPage), [policyPage]);

    return (
        <div>
            <Hero orange={true} />
            <Policy
                pageHeader={'Privacy Policy'}
                date={'July 2023'}
                headerText={`This Privacy Policy ("Policy") explains how YDF Interiors ("we," "us," or "our") collects, uses, discloses,
                        and protects personal information obtained from users ("you" or "your") 
                        of our website [www.ydf.world] ("Website"). We are committed to protecting your 
                        privacy and ensuring the security of your personal information in accordance with 
                        the applicable data protection laws in the United Kingdom, including the General 
                        Data Protection Regulation (GDPR).`}
                sectionPoints={[
                    {
                        to: 'info',
                        title: 'Information We Collect',
                    },
                    {
                        to: 'contact',
                        title: 'Contact Details and Financial Information',
                    },
                    {
                        to: 'personal_use',
                        title: 'Use of Personal Information',
                    },
                    {
                        to: 'personal_disclosure',
                        title: 'Disclosure of Personal Information',
                    },
                    {
                        to: 'collect',
                        title: 'Information Collected from this Website and How it is Used',
                    },
                    {
                        to: 'agents',
                        title: 'Agents',
                    },
                    {
                        to: 'cookies',
                        title: 'Cookies',
                    },
                    {
                        to: 'data',
                        title: 'Data Retention',
                    },
                    {
                        to: 'security',
                        title: 'Security',
                    },
                    {
                        to: 'rights',
                        title: 'Your Rights',
                    },
                    {
                        to: 'children',
                        title: `Children's Privacy`,
                    },
                ]}
                sections={[
                    {
                        to: 'info',
                        title: 'Information We Collect',
                        subtitle: 'We may collect the following types of personal information from you:',
                        description: [
                            `1.1. Information You Provide Voluntarily: When you use our Website or communicate 
                        with us, you may voluntarily provide us with personal information, such as your name, 
                        email address, postal address, telephone number, and any other information you choose to provide.`,
                            `1.2. Information Automatically Collected: When you access our Website, certain information may be 
                        automatically collected, including your IP address, browser type, operating system, referring 
                        website, pages visited, and the duration of your visit. We may use cookies or similar technologies 
                        to collect this information. Please refer to our Cookie Policy for more information.`,
                        ],
                    },
                    {
                        to: 'contact',
                        title: 'Contact Details and Financial Information',
                        subtitle: 'In the course of doing business with us we will collect the following information:',
                        description: [
                            `- Your name and any other related names you provide in the course of the transaction, 
                            postal address, delivery address, email address(es), telephone number(s) and bank / credit card details.`,
                        ],
                    },
                    {
                        to: 'personal_use',
                        title: 'Use of Personal Information',
                        subtitle: 'We use the personal information we collect for the following purposes:',
                        description: [
                            `2.1. To Provide and Improve Our Services: We may use your personal information 
                            to operate, maintain, and improve our Website and services, to respond to your 
                            inquiries and requests, and to personalize your experience.`,
                            `2.2. Communication: We may use your personal information to communicate with 
                            you, including sending you \service-related information, updates, newsletters, 
                            promotional materials, and other communications that may be of interest to you. 
                            You have the option to opt out of receiving such communications at any time.`,
                            `2.3. Compliance with Legal Obligations: We may process your personal information 
                            to comply with applicable laws, regulations, legal processes, or enforceable 
                            governmental requests.`,
                        ],
                    },
                    {
                        to: 'personal_disclosure',
                        title: 'Disclosure of Personal Information',
                        subtitle: 'We may disclose your personal information to the following entities and for the following purposes:',
                        description: [
                            `3.1. Service Providers: We may engage third-party service providers who 
                            assist us in operating our Website and providing our services. These 
                            service providers will have access to your personal information solely 
                            for the purpose of performing their duties.`,
                            `3.2. Legal Compliance and Protection: We may disclose your personal information 
                            if required by law or if we believe that such disclosure is necessary to protect 
                            our rights, defend against legal claims, or comply with a judicial proceeding, 
                            court order, or legal process.`,
                        ],
                    },
                    {
                        to: 'collect',
                        title: 'Information Collected from this Website and How it is Used',
                        description: [
                            `The information you provide enables us and its agents and data processors 
                            (see below) to complete transactions you request through the website or in 
                            store. Our store physical credit card details and transaction amounts 
                            from telephone and in store orders for 6 years in accordance with UK record 
                            keeping rules. After this date all records are destroyed. Electronic storage 
                            of credit and debit card details are stored indefinitely online to be used 
                            for future online transactions.`,
                            `When you register with us, we will require your email address. We may also 
                            use the contact details supplied by you for marketing purposes, such as 
                            mailshots and email updates but only with your permission. We and/or its 
                            agents do not sell or rent information about its customers to third parties.`,
                            `We may also profile your web activity so that we can gain a better understanding 
                            of our users. This could include, for instance, compiling lists of the most 
                            popular search terms, based on individual searches. This will help us to 
                            continually improve our service and may also be used for marketing purposes.`,
                        ],
                    },
                    {
                        to: 'agents',
                        title: 'Agents',
                        description: [
                            {
                                table: [
                                    {
                                        header: 'ZOHO CRM',
                                        first: `Names, postal and delivery addresses, email, telephone, fax 
                                            numbers, historical financial transactions, any other information you give to us`,
                                        second: `ZOHO CRM store his information for us to use for future transactions or to 
                                            contact you about our products and services. On the basis that you do not request 
                                            to be forgotten, we will store this data indefinitely for the above purposes.`,
                                    },
                                    {
                                        header: 'Mailchimp',
                                        first: `Names, company names and email addresses`,
                                        second: `Mailchimp store this information for us to contact you about our products 
                                            and services. On the basis that you do not request to be forgotten, we will store 
                                            this data indefinitely for the above purposes. If you unsubscribe from a mailing 
                                            list your details will be removed from the associated mailing list.`,
                                    },
                                    {
                                        header: 'Google Suite',
                                        first: `Names, postal and delivery addresses, email, telephone, fax numbers, historical 
                                            financial transactions, any other information you give to us`,
                                        second: `Google Suite (Google Drive and Gmail and Google Calander) store this 
                                            information for us to use for future transactions or to contact you about our 
                                            products and services. On the basis that you do not request to be forgotten, 
                                            we will store this data indefinitely for the above purposes.`,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        to: 'cookies',
                        title: 'Cookies',
                        description: [
                            `Our website uses cookies to keep track of your orders. A cookie 
                            is a small piece of text data, which is placed on your hard drive by our web 
                            server. This enables us to recognize your registration details automatically 
                            and serves to make your browsing experience more comfortable. The cookie received 
                            by your computer is unique to you and can only be read by the originating web 
                            server. We do not use cookies to examine your surfing behavior before or after 
                            leaving the website. In order to access your account on the website, you will 
                            need to use a browser that accepts cookies (please ensure that this feature is 
                            enabled).`,
                        ],
                    },
                    {
                        to: 'data',
                        title: 'Data Retention',
                        description: [
                            `We retain your personal information for as long as necessary to fulfill 
                            the purposes outlined in this Policy, unless a longer retention period is 
                            required or permitted by law. When we no longer need your personal 
                            information, we will securely delete or anonymize it.`,
                        ],
                    },
                    {
                        to: 'security',
                        title: 'Security',
                        description: [
                            `We take appropriate technical and organizational measures to protect 
                            your personal information from unauthorized access, disclosure, alteration, 
                            or destruction. However, no method of transmission over the internet or 
                            electronic storage is completely secure. Therefore, while we strive to 
                            protect your personal information, we cannot guarantee its absolute 
                            security.`,
                        ],
                    },
                    {
                        to: 'rights',
                        title: 'Your Rights',
                        description: [
                            `You have certain rights regarding your personal information 
                            under applicable data protection laws, including the right to access, 
                            correct, delete, or restrict the processing of your personal information. 
                            You also have the right to object to the processing of your personal 
                            information and the right to data portability. To exercise these rights, 
                            please contact us using the contact information provided below.`,
                        ],
                    },
                    {
                        to: 'children',
                        title: `Children's Privacy`,
                        description: [
                            `Our Website is not intended for children under the age of 16. 
                            We do not knowingly collect or solicit personal information from children. 
                            If you are a parent or guardian and believe that your child has provided us 
                            with personal information, please contact us using the contact hello@ydf.world.`,
                        ],
                    },
                ]}
            />
        </div>
    );
};

export default Privacy;
