import React, { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import '../assets/styles/carousel.scss';
import useWindowSize from '../hooks/useWindowSize';

const slidePointsList = [
    ['', 'RESIDENTIAL, VILLA', 'RESIDENTIAL, VILLA', 'COMMERCIAL, SPA', 'RESIDENTIAL, APARTMENT', 'COMMERCIAL, RESTAURANT', ''],
    ['', 'RESIDENTIAL, VILLA', 'RESIDENTIAL, VILLA', 'RESIDENTIAL, VILLA', 'COMMERCIAL, SPA', 'RESIDENTIAL, HOUSE', 'RESIDENTIAL, HOUSE', 'RESIDENTIAL, HOUSE', 'RESIDENTIAL, HOUSE', 'COMMERCIAL, RESTAURANT', ''],
];

const Carousel = ({ images, animate }) => {
    const { width } = useWindowSize();
    const [currentIndex, setCurrentIndex] = useState(1);
    const [waitForAnimation, setWaitForAnimation] = useState(false);
    const [prevIndex, setPrevIndex] = useState(5);
    const [disableButton, setDisableButton] = useState(false);
    const [zIndex, setZIndex] = useState(1);

    const slide1 = useRef();
    const slide2 = useRef();
    const slide3 = useRef();
    const slide4 = useRef();
    const slide5 = useRef();
    const slide6 = useRef();
    const slide7 = useRef();
    const slide8 = useRef();
    const slide9 = useRef();

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    function changeDirection(direction) {
        for (let i = 1; i < images.length + 1; i++) {
            eval(`slide${i}.current.children[0].children[0].style.${direction} = "0"`);
            eval(`slide${i}.current.children[0].children[0].style.${direction == 'right' ? 'left' : 'right'} = ""`);
        }
    }

    useEffect(() => {
        const innerFunc = async () => {
            setWaitForAnimation(false);
            if ((currentIndex === 1 && (prevIndex === 5 || prevIndex === 9)) || ((currentIndex === 5 || currentIndex === 9) && prevIndex === 1)) {
                await delay(500);
                setWaitForAnimation(true);
            } else {
            }
        };

        innerFunc();
    }, [currentIndex]);

    const nextSlide = async (index) => {
        setDisableButton(true);
        changeDirection('left');
        setPrevIndex(currentIndex);
        if (index < images.length + 1 || (!index && currentIndex < images.length)) {
            // eval(`slide${currentIndex}.current.children[0].children[0].style.objectPosition = "left"`);
            eval(`slide${currentIndex}.current.children[0].style.animation = "hideLeftSlide 0.6s both linear"`);
            eval(`slide${currentIndex}.current.style.zIndex = ${zIndex + 2}`);
            eval(`slide${index ? index : currentIndex + 1}.current.style.zIndex = ${zIndex + 1}`);
            eval(`slide${images.length}.current.children[0].className = "carousel_image_wrapper"`);
            eval(`slide${index ? index : currentIndex + 1}.current.children[0].style.width = "100%"`);
            eval(`slide${index ? index : currentIndex + 1}.current.children[0].children[0].style.transform = "scale(1)"`);

            !index && setCurrentIndex((prev) => prev + 1);
            setZIndex((prev) => prev + 1);

            await delay(600);
            eval(`slide${currentIndex}.current.children[0].style.animation = ""`);
            eval(`slide${currentIndex}.current.children[0].style.width = "0%"`);
            eval(`slide${currentIndex}.current.children[0].children[0].style.transform = "scale(1.15)"`);
            setDisableButton(false);
            // eval(`slide${currentIndex}.current.children[0].children[0].style.objectPosition = "center"`);
        } else {
            eval(`slide${currentIndex}.current.children[0].style.animation = "hideLeftSlide 0.6s both linear"`);
            eval(`slide${currentIndex}.current.style.zIndex = ${zIndex + 2}`);
            eval(`slide${1}.current.style.zIndex = ${zIndex + 1}`);
            eval(`slide${images.length}.current.children[0].className = "carousel_image_wrapper"`);
            eval(`slide${1}.current.children[0].style.width = "100%"`);
            eval(`slide${1}.current.children[0].children[0].style.transform = "scale(1)"`);

            setCurrentIndex(1);
            setZIndex((prev) => prev + 1);

            await delay(600);
            eval(`slide${currentIndex}.current.children[0].style.animation = ""`);
            eval(`slide${currentIndex}.current.children[0].style.width = "0%"`);
            eval(`slide${currentIndex}.current.children[0].children[0].style.transform = "scale(1.15)"`);
            setDisableButton(false);
        }
    };
    const prevSlide = async (index) => {
        changeDirection('right');
        setDisableButton(true);
        setPrevIndex(currentIndex);
        if (index > 0 || (!index && currentIndex > 1)) {
            eval(`slide${currentIndex}.current.children[0].style.animation = "hideRightSlide 0.6s both linear"`);
            eval(`slide${currentIndex}.current.style.zIndex = ${zIndex + 2}`);
            eval(`slide${index ? index : currentIndex - 1}.current.style.zIndex = ${zIndex + 1}`);
            eval(`slide${images.length}.current.children[0].className = "carousel_image_wrapper"`);
            eval(`slide${index ? index : currentIndex - 1}.current.children[0].style.width = "100%"`);
            eval(`slide${index ? index : currentIndex - 1}.current.children[0].children[0].style.transform = "scale(1)"`);

            !index && setCurrentIndex((prev) => prev - 1);
            setZIndex((prev) => prev + 1);

            await delay(600);
            eval(`slide${currentIndex}.current.children[0].style.animation = ""`);
            eval(`slide${currentIndex}.current.children[0].style.width = "0%"`);
            eval(`slide${currentIndex}.current.children[0].children[0].style.transform = "scale(1.15)"`);
            setDisableButton(false);
        } else {
            eval(`slide${currentIndex}.current.children[0].style.animation = "hideRightSlide 0.6s both linear"`);
            eval(`slide${currentIndex}.current.style.zIndex = ${zIndex + 2}`);
            eval(`slide${images.length}.current.style.zIndex = ${zIndex + 1}`);
            eval(`slide${images.length}.current.children[0].className = "carousel_image_wrapper"`);
            eval(`slide${images.length}.current.children[0].style.width = "100%"`);
            eval(`slide${images.length}.current.children[0].children[0].style.transform = "scale(1)"`);

            setCurrentIndex(images.length);
            setZIndex((prev) => prev + 1);

            await delay(600);
            eval(`slide${currentIndex}.current.children[0].style.animation = ""`);
            eval(`slide${currentIndex}.current.children[0].style.width = "0%"`);
            eval(`slide${currentIndex}.current.children[0].children[0].style.transform = "scale(1.15)"`);
            setDisableButton(false);
        }
    };

    const swipeHandler = useSwipeable({
        onSwipedLeft: () => !disableButton && nextSlide(),
        onSwipedRight: () => !disableButton && prevSlide(),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true,
    });

    return (
        <div className="carousel_wrapper" {...swipeHandler}>
            <div className="carousel_container"
                style={{
                    position: 'absolute',
                    backgroundColor: "white",
                    height: animate ? "0px" : "795px",
                    width: '100vw',
                    transition: '.4s ease-in-out',
                    zIndex: 100000000
                }}>

            </div>
            <div className="carousel_container">
                {images.map((src, i) => (
                    <div className={`carousel_item`} key={i} ref={eval(`slide${i + 1}`)}>
                        <div className={`carousel_image_wrapper ${!i ? 'carousel_image_wrapper_first' : ''}`}>
                            <img src={src} alt={src} style={{ objectPosition: i === 3 ? 'center 100%' : '', height: "100%", transform: animate ? "scale(1)" : "scale(1.25)" }} className="carousel_image_scaled" />
                        </div>
                    </div>
                ))}
                <div className="sl-bottom">
                    <div className="points-window">
                        <div
                            className="text pos-absolute points-track"
                            style={{
                                transform:
                                    prevIndex !== currentIndex
                                        ? currentIndex !== 1 || (currentIndex === 1 && prevIndex === 2)
                                            ? `translateX(-${(currentIndex + 1) * 320 - 320}px)`
                                            : prevIndex === 5 || prevIndex === 9
                                            ? waitForAnimation
                                                ? `translateX(-320px)`
                                                : `translateX(0px)`
                                            : ''
                                        : (currentIndex === 5 || currentIndex === 9) && prevIndex === 1
                                        ? waitForAnimation
                                            ? `translateX(-${(images.length - 2) * 320}px)`
                                            : `translateX(-${(images.length - 1) * 320}px)`
                                        : '',
                                transition: 'all 0.5s ease-in-out',
                                width: (images.length + 2) * 320,
                                animation:
                                    currentIndex === 1 && (prevIndex === 5 || prevIndex === 9) && !waitForAnimation
                                        ? 'animateToFirst 0.5s forwards'
                                        : currentIndex === 5 && prevIndex === 1 && !waitForAnimation
                                        ? 'animateToLast 0.5s forwards'
                                        : currentIndex === 9 && prevIndex === 1 && !waitForAnimation
                                        ? 'animateToLastMobile 0.5s forwards'
                                        : '',
                            }}>
                            {images.length < 6 ? slidePointsList[0].map((point, i) => <span>{point}</span>) : slidePointsList[1].map((point, i) => <span>{point}</span>)}
                        </div>
                    </div>
                </div>

                <div className="carousel-dots-wrapper">
                    <ul className="carousel-dots">
                        {[...new Array(images.length)].map((_, i) => (
                            <li
                                key={`dot-${i}`}
                                className={`carousel-dot ${i + 1 === currentIndex ? 'carousel-dot-active' : ''}`}
                                onClick={() => {
                                    if (!disableButton) {
                                        setCurrentIndex(i + 1);

                                        currentIndex < i + 1 ? nextSlide(i + 1) : currentIndex > i + 1 && prevSlide(i + 1);
                                    }
                                }}>
                                <span></span>
                            </li>
                        ))}
                    </ul>
                </div>

                {animate ? (
                    <div className="carousel__controls">
                        <div className="sl-next-back" onClick={() => !disableButton && nextSlide()} />
                        <div className="sl-prev-back" onClick={() => !disableButton && prevSlide()} />
                        <div className="sl-prev" onClick={() => !disableButton && prevSlide()}>
                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" fill="white">
                                <path d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z" />
                            </svg>
                        </div>
                        <div className="sl-next" onClick={() => !disableButton && nextSlide()}>
                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" fill="white">
                                <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                            </svg>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default Carousel;
