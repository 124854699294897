import React, { useEffect } from 'react';
import { Hero } from '../../sections/hero';
import Policy from '../../sections/policy';
import useCheckPath from '../../hooks/useCheckPath';

const Cookies = ({ setPolicyPage }) => {
    const policyPage = useCheckPath();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setPolicyPage(policyPage);
    }, [policyPage]);

    return (
        <div>
            <Hero orange={true} />
            <Policy
                pageHeader={'YDF Living Cookie Policy'}
                date={'July 2023'}
                headerText={`This Cookie Policy outlines how our website uses cookies to enhance your browsing 
                experience and provide you with personalized services. By using our website, you consent to 
                the use of cookies as described in this policy.`}
                sectionPoints={[
                    {
                        to: 'what',
                        title: 'What are cookies?',
                    },
                    {
                        to: 'use',
                        title: 'How do we use cookies?',
                    },
                    {
                        to: 'consent',
                        title: 'Your Consent',
                    },
                    {
                        to: 'managing',
                        title: 'Managing Cookies',
                    },
                    {
                        to: 'changes',
                        title: 'Changes to this Policy',
                    },
                    {
                        to: 'contact',
                        title: 'Contact Us',
                    },
                ]}
                sections={[
                    {
                        to: 'what',
                        title: 'What are cookies?',
                        description: [
                            `Cookies are small text files that are stored on your device (computer, 
                            tablet, mobile phone) when you visit a website. They are widely used to make 
                            websites work more efficiently and to provide valuable information to website owners.`,
                        ],
                    },
                    {
                        to: 'use',
                        title: 'How do we use cookies?',
                        subtitle: `We use cookies for various purposes, including but not limited to:`,
                        description: [
                            {
                                points: [
                                    `Essential Cookies: These cookies are necessary for the proper functioning of 
                                    our website. They enable you to navigate the site and use its features securely.`,
                                    `Analytical Cookies: We use analytical cookies to gather information about how visitors 
                                    use our website. This data helps us understand user behavior and make improvements to 
                                    enhance user experience.`,
                                    `Functional Cookies: Functional cookies allow our website to remember choices you make 
                                    (such as your username, language, or region) and provide enhanced, more personalized features.`,
                                    `Advertising and Targeting Cookies: We may use advertising and targeting cookies to deliver 
                                    relevant advertisements and content based on your interests and browsing activities.`,
                                    `Third-Party Cookies: Our website may also incorporate content and sharing tools from 
                                    third-party websites (such as social media platforms). These third-party sites may set 
                                    cookies of their own, and their usage is governed by their respective privacy policies.`,
                                ],
                            },
                        ],
                    },
                    {
                        to: 'consent',
                        title: 'Your Consent',
                        description: [
                            `By using our website, you consent to the placement of cookies on your device as 
                            described in this Cookie Policy. You have the option to manage your cookie preferences through 
                            your browser settings. Please note that blocking or disabling certain cookies may affect the 
                            functionality of our website and limit your access to certain features.`,
                        ],
                    },
                    {
                        to: 'managing',
                        title: 'Managing Cookies',
                        description: [
                            `You can manage your cookie preferences through your web browser settings. 
                            Most web browsers allow you to control cookies through their settings. You can usually find 
                            these settings in the "Options" or "Preferences" menu of your browser. The following links 
                            may be helpful in understanding your browser settings:`,
                            {
                                points: [`Cookie settings in Internet Explorer`, `Cookie settings in Firefox`, `Cookie settings in Chrome`, `Cookie settings in Safari`],
                            },
                            `Please note that modifying your browser settings to block or delete cookies may impact 
                            your experience on our website and may result in certain features not functioning correctly.`,
                        ],
                    },
                    {
                        to: 'changes',
                        title: 'Changes to this Policy',
                        description: [
                            `We may update this Cookie Policy from time to time to reflect changes in our practices and services. 
                            Any changes will be posted on this page with an updated effective date. We encourage you to review 
                            this policy periodically to stay informed about how we use cookies.`,
                        ],
                    },
                    {
                        to: 'contact',
                        title: 'Contact Us',
                        description: [
                            `If you have any questions, concerns, or requests related to this Cookie Policy, please feel free to contact 
                            usat hello@ydf.world.`,
                        ],
                    },
                ]}
            />
        </div>
    );
};

export default Cookies;
