import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';

const Header = ({orange}) => {
    const [isOpen,
        setIsOpen] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    return (
        <div>
            <div className="logo">
                <Link to="/">
                    <svg
                        width="135"
                        height="61"
                        viewBox="0 0 135 61"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M59.652 31.3478V10.0264C59.652 7.48657 61.162 6.06381 63.9208 6.06381H74.7344C81.893 6.06381 87.8255 11.6243 87.9568 18.4758C88.0218 21.7163 86.7525 24.8244 84.3882 27.2324C81.8272 29.8377 78.2369 31.3478 74.5158 31.3478H59.652ZM94.3706 18.7166C94.3706 8.40655 85.5706 -2.92448e-05 74.7567 -2.92448e-05H53.2599V37.4333H74.7344C85.5489 37.4333 94.3706 29.0273 94.3706 18.7166Z"
                            fill={orange ? '#804E3B' : 'white'}/>
                        <path
                            d="M31.413 16.5713L47.1524 0.240737H38.2434L23.6197 15.4989L8.90955 0.240737H0L15.8049 16.5713C16.0897 16.8778 16.3523 17.1404 16.6148 17.4029C19.0667 19.9204 20.4022 21.2997 20.4022 27.035V37.2798H26.8158V27.0567C26.8158 21.387 28.2387 19.8987 30.6253 17.4252C30.8662 17.1404 31.1283 16.8778 31.413 16.5713Z"
                            fill={orange ? '#804E3B' : 'white'}/>
                        <path
                            d="M112.474 21.7595H128.936V15.718H108.687V9.87255C108.687 7.26774 109.759 6.26104 112.54 6.26104H135V0.241035H102.361V37.2803H108.687V25.5246C108.687 22.6789 109.979 21.7595 112.474 21.7595Z"
                            fill={orange ? '#804E3B' : 'white'}/>
                        <path d="M15.1968 60.2173H13.681V51.1976H15.1968V60.2173Z" fill={orange ? '#804E3B' : 'white'}/>
                        <path
                            d="M29.9766 51.1976V60.2173H27.867L24.0771 53.3705H23.8245V60.2173H22.3843V51.1976H24.4941L28.2838 58.0444H28.5364V51.1976H29.9766Z"
                            fill={orange ? '#804E3B' : 'white'}/>
                        <path
                            d="M43.4927 52.5366H40.6126V60.2173H39.0967V52.5366H36.2037V51.1976H43.4927V52.5366Z"
                            fill={orange ? '#804E3B' : 'white'}/>
                        <path
                            d="M56.3775 58.8402V60.2173H49.7327V51.1976H56.2133V52.5744H51.2486V54.899H55.1775V56.2507H51.2486V58.8402H56.3775Z"
                            fill={orange ? '#804E3B' : 'white'}/>
                        <path
                            d="M66.7862 52.5366H64.4998V55.2905H66.7862C68.138 55.2905 68.6307 54.8231 68.6307 53.9137C68.6307 53.0041 68.138 52.5366 66.7862 52.5366ZM68.7065 56.3265L70.2097 60.2173H68.6181L67.2284 56.6296H64.4998V60.2173H62.9839V51.1976H67.1022C69.0475 51.1976 70.1718 52.2334 70.1718 53.9137C70.1718 55.063 69.6539 55.9095 68.7065 56.3265Z"
                            fill={orange ? '#804E3B' : 'white'}/>
                        <path d="M78.5594 60.2173H77.0436V51.1976H78.5594V60.2173Z" fill={orange ? '#804E3B' : 'white'}/>
                        <path
                            d="M92.366 55.7075C92.366 53.9261 91.5197 52.4103 89.6122 52.4103C87.6921 52.4103 86.8456 53.9261 86.8456 55.7075C86.8456 57.4885 87.6921 59.0046 89.6122 59.0046C91.5197 59.0046 92.366 57.4885 92.366 55.7075ZM93.9075 55.7075C93.9075 58.3349 92.404 60.3816 89.6122 60.3816C86.8078 60.3816 85.3046 58.3349 85.3046 55.7075C85.3046 53.08 86.8078 51.0333 89.6122 51.0333C92.404 51.0333 93.9075 53.08 93.9075 55.7075Z"
                            fill={orange ? '#804E3B' : 'white'}/>
                        <path
                            d="M104.392 52.5366H102.105V55.2905H104.392C105.743 55.2905 106.236 54.8231 106.236 53.9137C106.236 53.0041 105.743 52.5366 104.392 52.5366ZM106.312 56.3265L107.815 60.2173H106.224L104.834 56.6296H102.105V60.2173H100.589V51.1976H104.708C106.653 51.1976 107.777 52.2334 107.777 53.9137C107.777 55.063 107.259 55.9095 106.312 56.3265Z"
                            fill={orange ? '#804E3B' : 'white'}/>
                        <path
                            d="M118.856 55.038C120.283 55.2906 121.319 56.074 121.319 57.6275C121.319 59.0805 120.131 60.3816 117.807 60.3816C115.622 60.3816 114.245 59.2572 114.068 57.5771H115.596C115.786 58.6635 116.733 59.055 117.845 59.055C119.007 59.055 119.79 58.5246 119.79 57.6655C119.79 56.9203 119.247 56.5286 118.451 56.3897L116.746 56.0991C115.356 55.8592 114.422 55.0507 114.422 53.661C114.422 52.221 115.571 51.0333 117.744 51.0333C119.639 51.0333 120.915 52.044 121.117 53.5727H119.576C119.386 52.7894 118.805 52.3599 117.719 52.3599C116.67 52.3599 115.95 52.8273 115.95 53.6232C115.95 54.3179 116.519 54.621 117.226 54.7474L118.856 55.038Z"
                            fill={orange ? '#804E3B' : 'white'}/>
                    </svg>

                    {/* <svg width="130" height="55" viewBox="0 0 130 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M90.5186 17.8556C90.5186 8.02265 82.0658 0 71.6749 0H51.0371V35.7018H71.6653C82.0466 35.7018 90.5186 27.6886 90.5186 17.8556ZM57.168 29.8991V9.5662C57.168 7.14607 58.6168 5.78355 61.2649 5.78355H71.6462C78.5254 5.78355 84.215 11.0907 84.3493 17.6174C84.4165 20.7045 83.1884 23.6773 80.9241 25.9736C78.4679 28.4604 75.0138 29.8991 71.4447 29.8991H57.168Z"
                            fill={orange ? '#804E3B' : 'white'}
                        />
                        <path
                            d="M30.1569 15.7423L45.2593 0H36.706L22.668 14.7121L8.55323 0H0L15.1695 15.7423C15.4476 16.0408 15.6969 16.2911 15.9462 16.5414C18.2955 18.9678 19.5804 20.2965 19.5804 25.8231V35.7018H25.7364V25.852C25.7364 20.3831 27.098 18.9485 29.3897 16.5703C29.6295 16.2911 29.8788 16.0408 30.1569 15.7423Z"
                            fill={orange ? '#804E3B' : 'white'}
                        />
                        <path
                            d="M108.066 20.7394H124.096V14.9142H104.38V9.28169C104.38 6.7687 105.421 5.79624 108.134 5.79624H130V0H98.2227V35.7018H104.38V24.3692C104.38 21.6252 105.635 20.7394 108.066 20.7394Z"
                            fill={orange ? '#804E3B' : 'white'}
                        />
                        <path d="M12.5195 49.2109H13.7359V53.9125H16.3714V55.0004H12.5195V49.2109Z" fill={orange ? '#804E3B' : 'white'} />
                        <path d="M19.2617 49.2109H20.2247V55.0004H19.2617V49.2109Z" fill={orange ? '#804E3B' : 'white'} />
                        <path d="M30.815 49.2109L28.1435 55.0004H26.7353L24.0742 49.2109H25.617L27.4912 53.3491L29.3964 49.2109H30.815Z" fill={orange ? '#804E3B' : 'white'} />
                        <path d="M32.7422 49.2109H33.7052V55.0004H32.7422V49.2109Z" fill={orange ? '#804E3B' : 'white'} />
                        <path d="M43.3343 49.2109V55.0004H42.3379L39.7188 51.484V55.0004H38.5195V49.2109H39.5248L42.1351 52.7274V49.2109H43.3343Z" fill={orange ? '#804E3B' : 'white'} />
                        <path
                            d="M51.5858 52.0117H52.8453V54.2861C52.1856 54.7467 51.2859 55.0004 50.4363 55.0004C48.567 55.0004 47.1875 53.788 47.1875 52.1057C47.1875 50.4139 48.567 49.2109 50.4662 49.2109C51.5158 49.2109 52.3855 49.5493 52.9653 50.179L52.0756 50.9497C51.6458 50.5173 51.136 50.32 50.5362 50.32C49.3767 50.32 48.587 51.053 48.587 52.1151C48.587 53.1677 49.3767 53.9102 50.5162 53.9102C50.8861 53.9102 51.2359 53.8444 51.5858 53.6658V52.0117Z"
                            fill={orange ? '#804E3B' : 'white'}
                        />
                        <path
                            d="M60.668 52.101C60.668 50.412 62.0693 49.2109 63.9579 49.2109C65.0038 49.2109 65.8771 49.5581 66.4457 50.2056L65.542 50.975C65.1358 50.5434 64.6281 50.3182 64.0189 50.3182C62.8816 50.3182 62.0794 51.0501 62.0794 52.1104C62.0794 53.1613 62.8816 53.9026 64.0189 53.9026C64.6281 53.9026 65.1358 53.6774 65.542 53.2364L66.4457 54.0058C65.8771 54.6532 65.0038 55.0004 63.9478 55.0004C62.0693 54.991 60.668 53.7806 60.668 52.101Z"
                            fill={orange ? '#804E3B' : 'white'}
                        />
                        <path
                            d="M69.334 52.1057C69.334 50.4421 70.7607 49.2109 72.7044 49.2109C74.6377 49.2109 76.0747 50.4327 76.0747 52.1057C76.0747 53.7786 74.6377 55.0004 72.7044 55.0004C70.7607 55.0004 69.334 53.7692 69.334 52.1057ZM74.6377 52.1057C74.6377 51.0436 73.8106 50.3106 72.7044 50.3106C71.6085 50.3106 70.771 51.0436 70.771 52.1057C70.771 53.1677 71.5981 53.9008 72.7044 53.9008C73.8106 53.9008 74.6377 53.1677 74.6377 52.1057Z"
                            fill={orange ? '#804E3B' : 'white'}
                        />
                        <path d="M83.7797 49.2109V55.0004H82.7832L80.1641 51.484V55.0004H78.9648V49.2109H79.9701L82.5804 52.7274V49.2109H83.7797Z" fill={orange ? '#804E3B' : 'white'} />
                        <path
                            d="M87.6309 52.101C87.6309 50.412 89.0321 49.2109 90.9208 49.2109C91.9667 49.2109 92.84 49.5581 93.4086 50.2056L92.5049 50.975C92.0987 50.5434 91.591 50.3182 90.9818 50.3182C89.8445 50.3182 89.0423 51.0501 89.0423 52.1104C89.0423 53.1613 89.8445 53.9026 90.9818 53.9026C91.591 53.9026 92.0987 53.6774 92.5049 53.2364L93.4086 54.0058C92.84 54.6532 91.9667 55.0004 90.9107 55.0004C89.0321 54.991 87.6309 53.7806 87.6309 52.101Z"
                            fill={orange ? '#804E3B' : 'white'}
                        />
                        <path d="M101.112 53.9222V55.0004H96.2969V49.2109H100.997V50.2892H97.7246V51.5423H100.611V52.5817H97.7246V53.9222H101.112Z" fill={orange ? '#804E3B' : 'white'} />
                        <path
                            d="M109.78 51.3091C109.78 52.6011 108.848 53.4073 107.368 53.4073H106.25V55.0004H104.965V49.2109H107.368C108.858 49.2109 109.78 50.0075 109.78 51.3091ZM108.485 51.3091C108.485 50.668 108.085 50.2989 107.302 50.2989H106.259V52.3097H107.302C108.085 52.3097 108.485 51.9405 108.485 51.3091Z"
                            fill={orange ? '#804E3B' : 'white'}
                        />
                        <path d="M114.436 50.2989H112.668V49.2109H117.483V50.2989H115.714V55.0004H114.436V50.2989Z" fill={orange ? '#804E3B' : 'white'} />
                    </svg> */}
                </Link>
            </div>
            <div className="contact-us-wrapper" ref={wrapperRef}>
                {isOpen && (
                    <div className="mobileV">
                        <a href="tel:+447388889977" className="contact-us-button">
                            <div className="contact-us-into">
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.9995 11.9791V14.3877C16.0004 14.6113 15.9545 14.8327 15.8648 15.0375C15.775 15.2424 15.6434 15.4263 15.4783 15.5775C15.3132 15.7286 15.1183 15.8437 14.906 15.9154C14.6938 15.987 14.4689 16.0136 14.2457 15.9935C11.7702 15.725 9.39235 14.8808 7.30312 13.5286C5.35937 12.2959 3.71141 10.6512 2.47627 8.71135C1.11669 6.61679 0.270593 4.23206 0.00652969 1.75036C-0.0135738 1.52834 0.0128649 1.30457 0.0841619 1.0933C0.155459 0.882034 0.270052 0.687898 0.420646 0.523253C0.57124 0.358607 0.754535 0.22706 0.958861 0.136987C1.16319 0.0469136 1.38407 0.000287778 1.60744 7.78413e-05H4.02086C4.41128 -0.00375708 4.78977 0.134222 5.0858 0.388297C5.38182 0.642373 5.57517 0.995207 5.62981 1.38103C5.73168 2.15185 5.92059 2.9087 6.19295 3.63713C6.30118 3.9245 6.32461 4.23682 6.26045 4.53707C6.19629 4.83732 6.04723 5.11292 5.83093 5.33121L4.80925 6.35087C5.95446 8.36092 7.62206 10.0252 9.6361 11.1682L10.6578 10.1485C10.8765 9.93264 11.1527 9.78387 11.4535 9.71984C11.7544 9.6558 12.0673 9.67918 12.3552 9.78721C13.0851 10.059 13.8435 10.2476 14.6158 10.3492C15.0066 10.4042 15.3635 10.6007 15.6186 10.9012C15.8737 11.2017 16.0093 11.5853 15.9995 11.9791Z"
                                        fill="#191919"/>
                                </svg>
                                +447388889977
                            </div>
                        </a>
                        <a href="tel:+447388889977" className="contact-us-button">
                            <div className="contact-us-into">
                                <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.6741 10.0755C11.5016 9.96226 11.3291 9.90566 11.1565 10.1321L10.4665 11.0377C10.2939 11.1509 10.1789 11.2075 9.94888 11.0943C9.08626 10.6415 7.87859 10.1321 6.84345 8.43396C6.78594 8.20755 6.90096 8.09434 7.01597 7.98113L7.53355 7.18868C7.64856 7.07547 7.59105 6.96226 7.53355 6.84906L6.84345 5.20755C6.67093 4.75472 6.4984 4.81132 6.32588 4.81132H5.86581C5.7508 4.81132 5.52077 4.86792 5.29073 5.09434C4.02556 6.33962 4.54313 8.09434 5.46326 9.22642C5.63578 9.45283 6.78594 11.4906 9.25879 12.566C11.099 13.3585 11.5016 13.2453 12.0192 13.1321C12.6518 13.0755 13.2843 12.566 13.5719 12.0566C13.6294 11.8868 13.9169 11.1509 13.6869 11.0377M9.14377 16.3585C6.78594 16.3585 5.00319 15.1132 5.00319 15.1132L2.1853 15.8491L2.8754 13.1321C2.8754 13.1321 1.72524 11.3774 1.72524 9.16981C1.72524 5.09434 5.11821 1.69811 9.31629 1.69811C13.2268 1.69811 16.5623 4.69811 16.5623 8.88679C16.5623 12.9623 13.2268 16.3019 9.14377 16.3585ZM0 18L4.77316 16.6981C6.15555 17.3947 7.69626 17.7309 9.24823 17.6747C10.8002 17.6184 12.3116 17.1715 13.6382 16.3768C14.9648 15.582 16.0622 14.4658 16.8259 13.1347C17.5895 11.8037 17.9937 10.3022 18 8.77359C18 3.90566 14.0895 0 9.14377 0C7.55639 0.00399723 5.99777 0.417245 4.62313 1.19859C3.24848 1.97993 2.10579 3.10211 1.30885 4.45336C0.511907 5.80461 0.0885224 7.33778 0.0808596 8.9002C0.0731969 10.4626 0.481524 11.9997 1.26518 13.3585"
                                        fill="#191919"/>
                                </svg>
                                +447388889977
                            </div>
                        </a>
                        <div className="contact-us-button">
                            <div className="contact-us-into-bottom">
                                <div
                                    style={{
                                    marginTop: '3.5px'
                                }}>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M19.4405 4.55161C18.4697 3.58039 17.3164 2.81077 16.0471 2.28706C14.7777 1.76334 13.4172 1.49587 12.0441 1.50005C6.28125 1.50005 1.59 6.16786 1.58766 11.9063C1.58511 13.7335 2.06676 15.5288 2.98359 17.1094L1.5 22.5L7.04297 21.053C8.57729 21.8842 10.2948 22.3192 12.0398 22.3186H12.0441C17.8064 22.3186 22.4972 17.6504 22.5 11.9124C22.5035 10.5439 22.2348 9.18839 21.7095 7.92471C21.1843 6.66103 20.413 5.51442 19.4405 4.55161ZM12.0441 20.5622H12.0403C10.4847 20.5627 8.95739 20.1464 7.61719 19.3566L7.29984 19.1691L4.01062 20.0279L4.88859 16.8361L4.68187 16.508C3.81206 15.1309 3.35121 13.5351 3.35297 11.9063C3.35297 7.1377 7.25344 3.25786 12.0473 3.25786C14.347 3.25375 16.5542 4.16327 18.1833 5.78636C19.8125 7.40944 20.7302 9.61318 20.7347 11.9129C20.7328 16.6819 16.8342 20.5622 12.0441 20.5622Z"
                                            fill="black"/>
                                        <circle cx="8" cy="12" r="1" fill="black"/>
                                        <circle cx="12" cy="12" r="1" fill="black"/>
                                        <circle cx="16" cy="12" r="1" fill="black"/>
                                    </svg>
                                </div>
                                Chat with us
                            </div>
                        </div>
                    </div>
                )}
                <div className="contact-us-main-button" onClick={() => setIsOpen(!isOpen)}>
                    contact us {isOpen
                        ? ( <> <div className="desktopV">
                            <svg
                                width="15"
                                height="8"
                                viewBox="0 0 15 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    id="path1"
                                    d="M13.5 7L7.5 1L1.5 7"
                                    stroke={orange
                                    ? '#000000'
                                    : 'white'}
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"/>
                            </svg>
                        </div> < div className = "mobileV" > <svg
                            width="15"
                            height="8"
                            viewBox="0 0 15 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                id="path1"
                                d="M13.5 1L7.5 7L1.5 1"
                                stroke={orange
                                ? '#000000'
                                : 'white'}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"/>
                        </svg> < /div>
                        </ >)
                        : ( <> <div className="mobileV">
                            <svg
                                width="15"
                                height="8"
                                viewBox="0 0 15 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    id="path1"
                                    d="M13.5 7L7.5 1L1.5 7"
                                    stroke={orange
                                    ? '#000000'
                                    : 'white'}
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"/>
                            </svg>
                        </div> < div className = "desktopV" > <svg
                            width="15"
                            height="8"
                            viewBox="0 0 15 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                id="path1"
                                d="M13.5 1L7.5 7L1.5 1"
                                stroke={orange
                                ? '#000000'
                                : 'white'}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"/>
                        </svg> < /div>
                        </ >)}
                </div>
                {isOpen && (
                    <div className="desktopV padding-30">
                        <a href="tel:+447388889977" className="contact-us-button">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.9995 11.9791V14.3877C16.0004 14.6113 15.9545 14.8327 15.8648 15.0375C15.775 15.2424 15.6434 15.4263 15.4783 15.5775C15.3132 15.7286 15.1183 15.8437 14.906 15.9154C14.6938 15.987 14.4689 16.0136 14.2457 15.9935C11.7702 15.725 9.39235 14.8808 7.30312 13.5286C5.35937 12.2959 3.71141 10.6512 2.47627 8.71135C1.11669 6.61679 0.270593 4.23206 0.00652969 1.75036C-0.0135738 1.52834 0.0128649 1.30457 0.0841619 1.0933C0.155459 0.882034 0.270052 0.687898 0.420646 0.523253C0.57124 0.358607 0.754535 0.22706 0.958861 0.136987C1.16319 0.0469136 1.38407 0.000287778 1.60744 7.78413e-05H4.02086C4.41128 -0.00375708 4.78977 0.134222 5.0858 0.388297C5.38182 0.642373 5.57517 0.995207 5.62981 1.38103C5.73168 2.15185 5.92059 2.9087 6.19295 3.63713C6.30118 3.9245 6.32461 4.23682 6.26045 4.53707C6.19629 4.83732 6.04723 5.11292 5.83093 5.33121L4.80925 6.35087C5.95446 8.36092 7.62206 10.0252 9.6361 11.1682L10.6578 10.1485C10.8765 9.93264 11.1527 9.78387 11.4535 9.71984C11.7544 9.6558 12.0673 9.67918 12.3552 9.78721C13.0851 10.059 13.8435 10.2476 14.6158 10.3492C15.0066 10.4042 15.3635 10.6007 15.6186 10.9012C15.8737 11.2017 16.0093 11.5853 15.9995 11.9791Z"
                                    fill="#191919"/>
                            </svg>
                            <a href="tel:+447388889977">+447388889977</a>
                        </a>
                        <div className="contact-us-button">
                            <svg
                                width="22"
                                height="22"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.6741 10.0755C11.5016 9.96226 11.3291 9.90566 11.1565 10.1321L10.4665 11.0377C10.2939 11.1509 10.1789 11.2075 9.94888 11.0943C9.08626 10.6415 7.87859 10.1321 6.84345 8.43396C6.78594 8.20755 6.90096 8.09434 7.01597 7.98113L7.53355 7.18868C7.64856 7.07547 7.59105 6.96226 7.53355 6.84906L6.84345 5.20755C6.67093 4.75472 6.4984 4.81132 6.32588 4.81132H5.86581C5.7508 4.81132 5.52077 4.86792 5.29073 5.09434C4.02556 6.33962 4.54313 8.09434 5.46326 9.22642C5.63578 9.45283 6.78594 11.4906 9.25879 12.566C11.099 13.3585 11.5016 13.2453 12.0192 13.1321C12.6518 13.0755 13.2843 12.566 13.5719 12.0566C13.6294 11.8868 13.9169 11.1509 13.6869 11.0377M9.14377 16.3585C6.78594 16.3585 5.00319 15.1132 5.00319 15.1132L2.1853 15.8491L2.8754 13.1321C2.8754 13.1321 1.72524 11.3774 1.72524 9.16981C1.72524 5.09434 5.11821 1.69811 9.31629 1.69811C13.2268 1.69811 16.5623 4.69811 16.5623 8.88679C16.5623 12.9623 13.2268 16.3019 9.14377 16.3585ZM0 18L4.77316 16.6981C6.15555 17.3947 7.69626 17.7309 9.24823 17.6747C10.8002 17.6184 12.3116 17.1715 13.6382 16.3768C14.9648 15.582 16.0622 14.4658 16.8259 13.1347C17.5895 11.8037 17.9937 10.3022 18 8.77359C18 3.90566 14.0895 0 9.14377 0C7.55639 0.00399723 5.99777 0.417245 4.62313 1.19859C3.24848 1.97993 2.10579 3.10211 1.30885 4.45336C0.511907 5.80461 0.0885224 7.33778 0.0808596 8.9002C0.0731969 10.4626 0.481524 11.9997 1.26518 13.3585"
                                    fill="#191919"/>
                            </svg>
                            <a href="tel:+447388889977">+447388889977</a>
                        </div>
                        <div className="contact-us-button">
                            <div
                                style={{
                                marginTop: '3.5px'
                            }}>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M19.4405 4.55161C18.4697 3.58039 17.3164 2.81077 16.0471 2.28706C14.7777 1.76334 13.4172 1.49587 12.0441 1.50005C6.28125 1.50005 1.59 6.16786 1.58766 11.9063C1.58511 13.7335 2.06676 15.5288 2.98359 17.1094L1.5 22.5L7.04297 21.053C8.57729 21.8842 10.2948 22.3192 12.0398 22.3186H12.0441C17.8064 22.3186 22.4972 17.6504 22.5 11.9124C22.5035 10.5439 22.2348 9.18839 21.7095 7.92471C21.1843 6.66103 20.413 5.51442 19.4405 4.55161ZM12.0441 20.5622H12.0403C10.4847 20.5627 8.95739 20.1464 7.61719 19.3566L7.29984 19.1691L4.01062 20.0279L4.88859 16.8361L4.68187 16.508C3.81206 15.1309 3.35121 13.5351 3.35297 11.9063C3.35297 7.1377 7.25344 3.25786 12.0473 3.25786C14.347 3.25375 16.5542 4.16327 18.1833 5.78636C19.8125 7.40944 20.7302 9.61318 20.7347 11.9129C20.7328 16.6819 16.8342 20.5622 12.0441 20.5622Z"
                                        fill="black"/>
                                    <circle cx="8" cy="12" r="1" fill="black"/>
                                    <circle cx="12" cy="12" r="1" fill="black"/>
                                    <circle cx="16" cy="12" r="1" fill="black"/>
                                </svg>
                            </div>
                            Chat with us
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
