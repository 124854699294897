import React, { useEffect, useState } from 'react';
import useCheckPath from '../../hooks/useCheckPath';
import Aos from 'aos';

import { Hero } from '../../sections/hero';
import { SectionOne } from '../../sections/one';
import { SectionTwo } from '../../sections/two';
import { SectionThree } from '../../sections/three';
import { SectionFour } from '../../sections/four';
import { SectionFive } from '../../sections/five';
import { SectionSix } from '../../sections/six';
import { SectionSeven } from '../../sections/seven';
import { SectionEight } from '../../sections/eigth';
import { SectionNine } from '../../sections/nine';
import { EnquireDrawer } from '../../components/enquireDrawer';
import { BroucherDrawer } from '../../components/broucher';
import { FormModal } from '../../components/form';

import 'aos/dist/aos.css';
import './style.scss';

export const Main = ({ setPolicyPage }) => {
    const [open, setOpen] = useState(false);
    const [broucher, setBroucher] = useState(false);
    const [title, setTitle] = useState('');

    const policyPage = useCheckPath();

    useEffect(() => {
        Aos.init({
            // disable: function () {
            //     let maxWidth = 1024;
            //     return window.innerWidth < maxWidth;
            // },
            once: true,
        });
    }, []);

    useEffect(() => {
        // Immediately check URL to see if enquiry=1 is present
        const searchParams = new URLSearchParams(window.location.search);
        const enquiry = searchParams.get('enquire');
        if (enquiry === '1') {
            setOpen(true); // Open the drawer if enquiry=1 is found
        }

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => setPolicyPage(policyPage), [policyPage]);

    const onToggle = () => {
        if (open) {
            document.documentElement.style.overflow = 'unset';
        } else {
            document.documentElement.style.overflow = 'hidden';
        }
        setOpen(!open);
    };
    const onToggleB = () => {
        if (broucher) {
            document.documentElement.style.overflow = 'unset';
        } else {
            document.documentElement.style.overflow = 'hidden';
        }
        setBroucher(!broucher);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="main-wrapper">
            <Hero />
            <SectionOne />
            <SectionTwo />
            <SectionThree onToggle={onToggle} setTitle={setTitle} />
            <SectionFour />
            <SectionFive onToggle={onToggle} />
            <SectionSix />
            <SectionSeven onToggleB={onToggleB} />
            <SectionEight onToggleB={onToggleB} />
            <SectionNine onToggle={onToggle} onToggleB={onToggleB} />
            <EnquireDrawer open={open} onClose={onToggle} title={title} />
            {/* <BroucherDrawer open={broucher} onClose={onToggleB} /> */}
            <FormModal open={broucher} onClose={onToggleB} />
        </div>
    );
};
