import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useCheckPath = () => {
    const location = useLocation();
    const [policyPage, setPolicyPage] = useState(false);

    useEffect(() => {
        if (location && location.pathname !== '/') {
            setPolicyPage(true);
        }
    }, [location]);

    return policyPage;
};

export default useCheckPath;
