import React from 'react';
import './style.scss';
import { Desktop, LargeDesktop, Mobile, Tablet } from '../../responsive';
import { Link } from 'react-router-dom';
import document from '../../assets/YDF GDPR Policy.pdf'

export const Footer = () => {
    return (
        <>
            <LargeDesktop>
                <footer className="footer-wrapper">
                    <div>
                        <Link to="/">
                            <img src="/images/logo/logo.svg" />
                        </Link>
                    </div>
                    <div className="footer-wrapper-item">
                        <div>
                            <div className="heading">LEGAL</div>
                            <Link to="/privacy" className="text">
                                Privacy Policy
                            </Link>
                            <Link to="/cookies" className="text">
                                Cookie Policy
                            </Link>
                            <a href={document} target='_blank' className='text'>
                                GDPR Policy
                            </a>
                        </div>
                        <div>
                            <div className="heading">STAY CONNECTED</div>
                            <div className="text social">
                                <a href="https://www.pinterest.co.uk/ydflivingconcept/">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.002 2C6.03081 2 2 6.02901 2 10.998C2 14.6815 4.21835 17.8469 7.38915 19.2408C7.36504 18.6141 7.38513 17.859 7.54588 17.1761C7.71869 16.445 8.70328 12.2714 8.70328 12.2714C8.70328 12.2714 8.41393 11.6969 8.41393 10.8494C8.41393 9.51573 9.18553 8.51953 10.15 8.51953C10.9699 8.51953 11.3637 9.13412 11.3637 9.86923C11.3637 10.6927 10.8372 11.9219 10.568 13.0627C10.3429 14.0187 11.0462 14.794 11.9906 14.794C13.6946 14.794 14.8439 12.6048 14.8439 10.0098C14.8439 8.03749 13.5137 6.56327 11.0985 6.56327C8.36973 6.56327 6.66577 8.59987 6.66577 10.8735C6.66577 11.6568 6.89886 12.2111 7.26055 12.6369C7.42532 12.8337 7.44943 12.9141 7.38915 13.139C7.34494 13.3037 7.24849 13.7014 7.20429 13.8621C7.14401 14.0911 6.95914 14.1714 6.75419 14.087C5.49632 13.5729 4.90958 12.1991 4.90958 10.6485C4.90958 8.09373 7.06765 5.02879 11.3436 5.02879C14.7796 5.02879 17.0422 7.51529 17.0422 10.1825C17.0422 13.7135 15.077 16.3486 12.1835 16.3486C11.211 16.3486 10.2987 15.8224 9.98526 15.2278C9.98526 15.2278 9.46283 17.3006 9.3503 17.7023C9.16142 18.3972 8.78768 19.0882 8.44608 19.6304C9.2747 19.8748 10.1341 19.9992 10.998 20C15.9692 20 20 15.971 20 11.002C20 6.03303 15.9732 2 11.002 2Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                                <a href="https://instagram.com/ydfworld?igshid=NTc4MTIwNjQ2YQ==">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M15.5 2H6.5C4.01472 2 2 4.01472 2 6.5V15.5C2 17.9853 4.01472 20 6.5 20H15.5C17.9853 20 20 17.9853 20 15.5V6.5C20 4.01472 17.9853 2 15.5 2ZM11.4556 8.14021C10.8626 8.05228 10.257 8.15356 9.72488 8.42965C9.19278 8.70575 8.7613 9.14259 8.49178 9.67805C8.22227 10.2135 8.12846 10.8203 8.2237 11.4122C8.31894 12.004 8.59837 12.5508 9.02225 12.9746C9.44613 13.3985 9.99288 13.6779 10.5847 13.7732C11.1766 13.8684 11.7834 13.7746 12.3188 13.5051C12.8543 13.2356 13.2911 12.8041 13.5672 12.272C13.8433 11.7399 13.9446 11.1343 13.8567 10.5413C13.767 9.93647 13.4851 9.3765 13.0528 8.94412C12.6204 8.51175 12.0604 8.2299 11.4556 8.14021ZM9.03402 7.09822C9.84617 6.67681 10.7705 6.52222 11.6756 6.65643C12.5988 6.79333 13.4535 7.22352 14.1134 7.88346C14.7734 8.54341 15.2036 9.3981 15.3405 10.3213C15.4747 11.2264 15.3201 12.1507 14.8987 12.9629C14.4773 13.775 13.8105 14.4336 12.9932 14.8449C12.1759 15.2563 11.2498 15.3995 10.3464 15.2541C9.44308 15.1088 8.60857 14.6823 7.96159 14.0353C7.31461 13.3883 6.88811 12.5538 6.74275 11.6505C6.59739 10.7471 6.74057 9.82094 7.15193 9.00366C7.56329 8.18638 8.22188 7.51962 9.03402 7.09822ZM15.9492 5.30078C15.535 5.30078 15.1992 5.63657 15.1992 6.05078C15.1992 6.46499 15.535 6.80078 15.9492 6.80078H15.9592C16.3734 6.80078 16.7092 6.46499 16.7092 6.05078C16.7092 5.63657 16.3734 5.30078 15.9592 5.30078H15.9492Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                                <a href="https://www.linkedin.com/company/ydf-experiential-interior-design-store/about/?viewAsMember=true">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.5604 2H3.53803C2.71719 2 2 2.59062 2 3.40183V18.4575C2 19.2732 2.71719 20 3.53803 20H18.556C19.3812 20 20 19.2683 20 18.4575V3.40183C20.0048 2.59062 19.3812 2 18.5604 2ZM7.57959 17.0039H5.00093V8.98625H7.57959V17.0039ZM6.37946 7.76723H6.36097C5.53571 7.76723 5.00133 7.1529 5.00133 6.38388C5.00133 5.6008 5.54977 5.00094 6.39352 5.00094C7.23727 5.00094 7.75356 5.59638 7.77204 6.38388C7.77164 7.1529 7.23727 7.76723 6.37946 7.76723ZM17.0039 17.0039H14.4252V12.62C14.4252 11.5697 14.0499 10.8521 13.117 10.8521C12.4042 10.8521 11.9824 11.3343 11.7947 11.804C11.7244 11.9727 11.7055 12.2025 11.7055 12.4372V17.0039H9.12686V8.98625H11.7055V10.102C12.0808 9.56763 12.667 8.79862 14.0311 8.79862C15.7238 8.79862 17.0043 9.91438 17.0043 12.3199L17.0039 17.0039Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/ydflivingconcept" className="facebook">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M20 14.769C20 18.045 18.047 19.998 14.771 19.998H13.7C13.205 19.998 12.8 19.593 12.8 19.098V13.905C12.8 13.662 12.998 13.455 13.241 13.455L14.825 13.428C14.951 13.419 15.059 13.329 15.086 13.203L15.401 11.484C15.428 11.322 15.302 11.169 15.131 11.169L13.214 11.196C12.962 11.196 12.764 10.998 12.755 10.755L12.719 8.55005C12.719 8.40605 12.836 8.28006 12.989 8.28006L15.149 8.24405C15.302 8.24405 15.419 8.12706 15.419 7.97406L15.383 5.81404C15.383 5.66104 15.266 5.54405 15.113 5.54405L12.683 5.58006C11.189 5.60706 10.001 6.83105 10.028 8.32505L10.073 10.8C10.082 11.052 9.884 11.25 9.632 11.259L8.552 11.277C8.399 11.277 8.28201 11.394 8.28201 11.547L8.30901 13.257C8.30901 13.41 8.426 13.527 8.579 13.527L9.659 13.509C9.911 13.509 10.109 13.707 10.118 13.95L10.199 19.08C10.208 19.584 9.803 19.998 9.299 19.998H7.229C3.953 19.998 2 18.045 2 14.76V7.22705C2 3.95105 3.953 1.99805 7.229 1.99805H14.771C18.047 1.99805 20 3.95105 20 7.22705V14.769Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className="heading">CONTACT INFORMATION</div>
                            <a href="tel:+447388889977" className="text">
                                t.: +447388889977
                            </a>
                            <a href="tel:0 208 099 1000" className="text">
                                t.: 0 208 099 1000
                            </a>
                            <a href="mailto:hello@ydf.world" className="text">
                                hello@ydf.world
                            </a>
                        </div>
                        <div>
                            <div className="heading">ADDRESS</div>
                            <a className="text">YDF London Store</a>
                            <a className="text">London, 70 Brompton Rd, SW3 1ER</a>
                        </div>
                    </div>
                </footer>
            </LargeDesktop>
            <Desktop>
                <footer className="footer-wrapper">
                    <div>
                        <Link to="/">
                            <img src="/images/logo/logo.svg" />
                        </Link>
                    </div>
                    <div className="footer-wrapper-item">
                        <div>
                            <div className="heading">LEGAL</div>
                            <Link to="/privacy" className="text">
                                Privacy Policy
                            </Link>
                            <Link to="/cookies" className="text">
                                Cookie Policy
                            </Link>
                            <a href={document} target='_blank' className='text'>
                                GDPR Policy
                            </a>
                        </div>
                        <div>
                            <div className="heading">STAY CONNECTED</div>
                            <div className="text social">
                                <a href="https://www.pinterest.co.uk/ydflivingconcept/">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.002 2C6.03081 2 2 6.02901 2 10.998C2 14.6815 4.21835 17.8469 7.38915 19.2408C7.36504 18.6141 7.38513 17.859 7.54588 17.1761C7.71869 16.445 8.70328 12.2714 8.70328 12.2714C8.70328 12.2714 8.41393 11.6969 8.41393 10.8494C8.41393 9.51573 9.18553 8.51953 10.15 8.51953C10.9699 8.51953 11.3637 9.13412 11.3637 9.86923C11.3637 10.6927 10.8372 11.9219 10.568 13.0627C10.3429 14.0187 11.0462 14.794 11.9906 14.794C13.6946 14.794 14.8439 12.6048 14.8439 10.0098C14.8439 8.03749 13.5137 6.56327 11.0985 6.56327C8.36973 6.56327 6.66577 8.59987 6.66577 10.8735C6.66577 11.6568 6.89886 12.2111 7.26055 12.6369C7.42532 12.8337 7.44943 12.9141 7.38915 13.139C7.34494 13.3037 7.24849 13.7014 7.20429 13.8621C7.14401 14.0911 6.95914 14.1714 6.75419 14.087C5.49632 13.5729 4.90958 12.1991 4.90958 10.6485C4.90958 8.09373 7.06765 5.02879 11.3436 5.02879C14.7796 5.02879 17.0422 7.51529 17.0422 10.1825C17.0422 13.7135 15.077 16.3486 12.1835 16.3486C11.211 16.3486 10.2987 15.8224 9.98526 15.2278C9.98526 15.2278 9.46283 17.3006 9.3503 17.7023C9.16142 18.3972 8.78768 19.0882 8.44608 19.6304C9.2747 19.8748 10.1341 19.9992 10.998 20C15.9692 20 20 15.971 20 11.002C20 6.03303 15.9732 2 11.002 2Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                                <a href="https://instagram.com/ydfworld?igshid=NTc4MTIwNjQ2YQ==">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M15.5 2H6.5C4.01472 2 2 4.01472 2 6.5V15.5C2 17.9853 4.01472 20 6.5 20H15.5C17.9853 20 20 17.9853 20 15.5V6.5C20 4.01472 17.9853 2 15.5 2ZM11.4556 8.14021C10.8626 8.05228 10.257 8.15356 9.72488 8.42965C9.19278 8.70575 8.7613 9.14259 8.49178 9.67805C8.22227 10.2135 8.12846 10.8203 8.2237 11.4122C8.31894 12.004 8.59837 12.5508 9.02225 12.9746C9.44613 13.3985 9.99288 13.6779 10.5847 13.7732C11.1766 13.8684 11.7834 13.7746 12.3188 13.5051C12.8543 13.2356 13.2911 12.8041 13.5672 12.272C13.8433 11.7399 13.9446 11.1343 13.8567 10.5413C13.767 9.93647 13.4851 9.3765 13.0528 8.94412C12.6204 8.51175 12.0604 8.2299 11.4556 8.14021ZM9.03402 7.09822C9.84617 6.67681 10.7705 6.52222 11.6756 6.65643C12.5988 6.79333 13.4535 7.22352 14.1134 7.88346C14.7734 8.54341 15.2036 9.3981 15.3405 10.3213C15.4747 11.2264 15.3201 12.1507 14.8987 12.9629C14.4773 13.775 13.8105 14.4336 12.9932 14.8449C12.1759 15.2563 11.2498 15.3995 10.3464 15.2541C9.44308 15.1088 8.60857 14.6823 7.96159 14.0353C7.31461 13.3883 6.88811 12.5538 6.74275 11.6505C6.59739 10.7471 6.74057 9.82094 7.15193 9.00366C7.56329 8.18638 8.22188 7.51962 9.03402 7.09822ZM15.9492 5.30078C15.535 5.30078 15.1992 5.63657 15.1992 6.05078C15.1992 6.46499 15.535 6.80078 15.9492 6.80078H15.9592C16.3734 6.80078 16.7092 6.46499 16.7092 6.05078C16.7092 5.63657 16.3734 5.30078 15.9592 5.30078H15.9492Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                                <a href="https://www.linkedin.com/company/ydf-experiential-interior-design-store/about/?viewAsMember=true">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.5604 2H3.53803C2.71719 2 2 2.59062 2 3.40183V18.4575C2 19.2732 2.71719 20 3.53803 20H18.556C19.3812 20 20 19.2683 20 18.4575V3.40183C20.0048 2.59062 19.3812 2 18.5604 2ZM7.57959 17.0039H5.00093V8.98625H7.57959V17.0039ZM6.37946 7.76723H6.36097C5.53571 7.76723 5.00133 7.1529 5.00133 6.38388C5.00133 5.6008 5.54977 5.00094 6.39352 5.00094C7.23727 5.00094 7.75356 5.59638 7.77204 6.38388C7.77164 7.1529 7.23727 7.76723 6.37946 7.76723ZM17.0039 17.0039H14.4252V12.62C14.4252 11.5697 14.0499 10.8521 13.117 10.8521C12.4042 10.8521 11.9824 11.3343 11.7947 11.804C11.7244 11.9727 11.7055 12.2025 11.7055 12.4372V17.0039H9.12686V8.98625H11.7055V10.102C12.0808 9.56763 12.667 8.79862 14.0311 8.79862C15.7238 8.79862 17.0043 9.91438 17.0043 12.3199L17.0039 17.0039Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/ydflivingconcept" className="facebook">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M20 14.769C20 18.045 18.047 19.998 14.771 19.998H13.7C13.205 19.998 12.8 19.593 12.8 19.098V13.905C12.8 13.662 12.998 13.455 13.241 13.455L14.825 13.428C14.951 13.419 15.059 13.329 15.086 13.203L15.401 11.484C15.428 11.322 15.302 11.169 15.131 11.169L13.214 11.196C12.962 11.196 12.764 10.998 12.755 10.755L12.719 8.55005C12.719 8.40605 12.836 8.28006 12.989 8.28006L15.149 8.24405C15.302 8.24405 15.419 8.12706 15.419 7.97406L15.383 5.81404C15.383 5.66104 15.266 5.54405 15.113 5.54405L12.683 5.58006C11.189 5.60706 10.001 6.83105 10.028 8.32505L10.073 10.8C10.082 11.052 9.884 11.25 9.632 11.259L8.552 11.277C8.399 11.277 8.28201 11.394 8.28201 11.547L8.30901 13.257C8.30901 13.41 8.426 13.527 8.579 13.527L9.659 13.509C9.911 13.509 10.109 13.707 10.118 13.95L10.199 19.08C10.208 19.584 9.803 19.998 9.299 19.998H7.229C3.953 19.998 2 18.045 2 14.76V7.22705C2 3.95105 3.953 1.99805 7.229 1.99805H14.771C18.047 1.99805 20 3.95105 20 7.22705V14.769Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className="heading">CONTACT INFORMATION</div>
                            <a href="tel:+447388889977" className="text">
                                t.: +447388889977
                            </a>
                            <a href="tel:0 208 099 1000" className="text">
                                t.: 0 208 099 1000
                            </a>
                            <a href="mailto:hello@ydf.world" className="text">
                                hello@ydf.world
                            </a>
                        </div>
                        <div>
                            <div className="heading">ADDRESS</div>
                            <a className="text">YDF London Store</a>
                            <a className="text">London, 70 Brompton Rd, SW3 1ER</a>
                        </div>
                    </div>
                </footer>
            </Desktop>
            <Tablet>
                <footer className="footer-wrapper">
                    <div>
                        <Link to="/">
                            <img src="/images/logo/logo.svg" />
                        </Link>
                    </div>
                    <div className="footer-wrapper-item">
                        <div>
                            <div className="heading">LEGAL</div>
                            <Link to="/privacy" className="text">
                                Privacy Policy
                            </Link>
                            <Link to="/cookies" className="text">
                                Cookie Policy
                            </Link>
                            <a href={document} target='_blank' className='text'>
                                GDPR Policy
                            </a>
                        </div>
                        <div>
                            <div className="heading">STAY CONNECTED</div>
                            <div className="text social">
                                <a href="https://www.pinterest.co.uk/ydflivingconcept/">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.002 2C6.03081 2 2 6.02901 2 10.998C2 14.6815 4.21835 17.8469 7.38915 19.2408C7.36504 18.6141 7.38513 17.859 7.54588 17.1761C7.71869 16.445 8.70328 12.2714 8.70328 12.2714C8.70328 12.2714 8.41393 11.6969 8.41393 10.8494C8.41393 9.51573 9.18553 8.51953 10.15 8.51953C10.9699 8.51953 11.3637 9.13412 11.3637 9.86923C11.3637 10.6927 10.8372 11.9219 10.568 13.0627C10.3429 14.0187 11.0462 14.794 11.9906 14.794C13.6946 14.794 14.8439 12.6048 14.8439 10.0098C14.8439 8.03749 13.5137 6.56327 11.0985 6.56327C8.36973 6.56327 6.66577 8.59987 6.66577 10.8735C6.66577 11.6568 6.89886 12.2111 7.26055 12.6369C7.42532 12.8337 7.44943 12.9141 7.38915 13.139C7.34494 13.3037 7.24849 13.7014 7.20429 13.8621C7.14401 14.0911 6.95914 14.1714 6.75419 14.087C5.49632 13.5729 4.90958 12.1991 4.90958 10.6485C4.90958 8.09373 7.06765 5.02879 11.3436 5.02879C14.7796 5.02879 17.0422 7.51529 17.0422 10.1825C17.0422 13.7135 15.077 16.3486 12.1835 16.3486C11.211 16.3486 10.2987 15.8224 9.98526 15.2278C9.98526 15.2278 9.46283 17.3006 9.3503 17.7023C9.16142 18.3972 8.78768 19.0882 8.44608 19.6304C9.2747 19.8748 10.1341 19.9992 10.998 20C15.9692 20 20 15.971 20 11.002C20 6.03303 15.9732 2 11.002 2Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                                <a href="https://instagram.com/ydfworld?igshid=NTc4MTIwNjQ2YQ==">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M15.5 2H6.5C4.01472 2 2 4.01472 2 6.5V15.5C2 17.9853 4.01472 20 6.5 20H15.5C17.9853 20 20 17.9853 20 15.5V6.5C20 4.01472 17.9853 2 15.5 2ZM11.4556 8.14021C10.8626 8.05228 10.257 8.15356 9.72488 8.42965C9.19278 8.70575 8.7613 9.14259 8.49178 9.67805C8.22227 10.2135 8.12846 10.8203 8.2237 11.4122C8.31894 12.004 8.59837 12.5508 9.02225 12.9746C9.44613 13.3985 9.99288 13.6779 10.5847 13.7732C11.1766 13.8684 11.7834 13.7746 12.3188 13.5051C12.8543 13.2356 13.2911 12.8041 13.5672 12.272C13.8433 11.7399 13.9446 11.1343 13.8567 10.5413C13.767 9.93647 13.4851 9.3765 13.0528 8.94412C12.6204 8.51175 12.0604 8.2299 11.4556 8.14021ZM9.03402 7.09822C9.84617 6.67681 10.7705 6.52222 11.6756 6.65643C12.5988 6.79333 13.4535 7.22352 14.1134 7.88346C14.7734 8.54341 15.2036 9.3981 15.3405 10.3213C15.4747 11.2264 15.3201 12.1507 14.8987 12.9629C14.4773 13.775 13.8105 14.4336 12.9932 14.8449C12.1759 15.2563 11.2498 15.3995 10.3464 15.2541C9.44308 15.1088 8.60857 14.6823 7.96159 14.0353C7.31461 13.3883 6.88811 12.5538 6.74275 11.6505C6.59739 10.7471 6.74057 9.82094 7.15193 9.00366C7.56329 8.18638 8.22188 7.51962 9.03402 7.09822ZM15.9492 5.30078C15.535 5.30078 15.1992 5.63657 15.1992 6.05078C15.1992 6.46499 15.535 6.80078 15.9492 6.80078H15.9592C16.3734 6.80078 16.7092 6.46499 16.7092 6.05078C16.7092 5.63657 16.3734 5.30078 15.9592 5.30078H15.9492Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                                <a href="https://www.linkedin.com/company/ydf-experiential-interior-design-store/about/?viewAsMember=true">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.5604 2H3.53803C2.71719 2 2 2.59062 2 3.40183V18.4575C2 19.2732 2.71719 20 3.53803 20H18.556C19.3812 20 20 19.2683 20 18.4575V3.40183C20.0048 2.59062 19.3812 2 18.5604 2ZM7.57959 17.0039H5.00093V8.98625H7.57959V17.0039ZM6.37946 7.76723H6.36097C5.53571 7.76723 5.00133 7.1529 5.00133 6.38388C5.00133 5.6008 5.54977 5.00094 6.39352 5.00094C7.23727 5.00094 7.75356 5.59638 7.77204 6.38388C7.77164 7.1529 7.23727 7.76723 6.37946 7.76723ZM17.0039 17.0039H14.4252V12.62C14.4252 11.5697 14.0499 10.8521 13.117 10.8521C12.4042 10.8521 11.9824 11.3343 11.7947 11.804C11.7244 11.9727 11.7055 12.2025 11.7055 12.4372V17.0039H9.12686V8.98625H11.7055V10.102C12.0808 9.56763 12.667 8.79862 14.0311 8.79862C15.7238 8.79862 17.0043 9.91438 17.0043 12.3199L17.0039 17.0039Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/ydflivingconcept" className="facebook">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M20 14.769C20 18.045 18.047 19.998 14.771 19.998H13.7C13.205 19.998 12.8 19.593 12.8 19.098V13.905C12.8 13.662 12.998 13.455 13.241 13.455L14.825 13.428C14.951 13.419 15.059 13.329 15.086 13.203L15.401 11.484C15.428 11.322 15.302 11.169 15.131 11.169L13.214 11.196C12.962 11.196 12.764 10.998 12.755 10.755L12.719 8.55005C12.719 8.40605 12.836 8.28006 12.989 8.28006L15.149 8.24405C15.302 8.24405 15.419 8.12706 15.419 7.97406L15.383 5.81404C15.383 5.66104 15.266 5.54405 15.113 5.54405L12.683 5.58006C11.189 5.60706 10.001 6.83105 10.028 8.32505L10.073 10.8C10.082 11.052 9.884 11.25 9.632 11.259L8.552 11.277C8.399 11.277 8.28201 11.394 8.28201 11.547L8.30901 13.257C8.30901 13.41 8.426 13.527 8.579 13.527L9.659 13.509C9.911 13.509 10.109 13.707 10.118 13.95L10.199 19.08C10.208 19.584 9.803 19.998 9.299 19.998H7.229C3.953 19.998 2 18.045 2 14.76V7.22705C2 3.95105 3.953 1.99805 7.229 1.99805H14.771C18.047 1.99805 20 3.95105 20 7.22705V14.769Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>

                        <div>
                            <div className="heading">CONTACT INFORMATION</div>
                            <a href="tel:+447388889977" className="text">
                                t.: +447388889977
                            </a>
                            <a href="tel:0 208 099 1000" className="text">
                                t.: 0 208 099 1000
                            </a>
                            <a href="mailto:hello@ydf.world" className="text">
                                hello@ydf.world
                            </a>
                        </div>
                        <div className="mt16">
                            <div className="heading">ADDRESS</div>
                            <a className="text">YDF London Store</a>
                            <a className="text">London, 70 Brompton Rd, SW3 1ER</a>
                        </div>
                    </div>
                </footer>
            </Tablet>
            <Mobile>
                <footer className="footer-wrapper">
                    <div className="logo-mobile">
                        <Link to="/">
                            <img src="/images/logo/logo.svg" />
                        </Link>
                    </div>
                    <div className="footer-wrapper-item">
                        <div>
                            <div className="heading">LEGAL</div>
                            <Link to="/privacy" className="text">
                                Privacy Policy
                            </Link>
                            <Link to="/cookies" className="text">
                                Cookie Policy
                            </Link>
                            <a href={document} target='_blank' className='text'>
                                GDPR Policy
                            </a>
                        </div>
                        <div>
                            <div className="heading">ADDRESS</div>
                            <a className="text">YDF London Store</a>
                            <a className="text">London, 70 Brompton Rd, SW3 1ER</a>
                        </div>

                        <div>
                            <div className="heading">CONTACT INFORMATION</div>
                            <a href="tel:+447388889977" className="text">
                                t.: +447388889977
                            </a>
                            <a href="tel:0 208 099 1000" className="text">
                                t.: 0 208 099 1000
                            </a>
                            <a href="mailto:hello@ydf.world" className="text">
                                hello@ydf.world
                            </a>
                        </div>
                        <div>
                            <div className="heading">STAY CONNECTED</div>
                            <div className="text social">
                                <a href="https://www.pinterest.co.uk/ydflivingconcept/">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.002 2C6.03081 2 2 6.02901 2 10.998C2 14.6815 4.21835 17.8469 7.38915 19.2408C7.36504 18.6141 7.38513 17.859 7.54588 17.1761C7.71869 16.445 8.70328 12.2714 8.70328 12.2714C8.70328 12.2714 8.41393 11.6969 8.41393 10.8494C8.41393 9.51573 9.18553 8.51953 10.15 8.51953C10.9699 8.51953 11.3637 9.13412 11.3637 9.86923C11.3637 10.6927 10.8372 11.9219 10.568 13.0627C10.3429 14.0187 11.0462 14.794 11.9906 14.794C13.6946 14.794 14.8439 12.6048 14.8439 10.0098C14.8439 8.03749 13.5137 6.56327 11.0985 6.56327C8.36973 6.56327 6.66577 8.59987 6.66577 10.8735C6.66577 11.6568 6.89886 12.2111 7.26055 12.6369C7.42532 12.8337 7.44943 12.9141 7.38915 13.139C7.34494 13.3037 7.24849 13.7014 7.20429 13.8621C7.14401 14.0911 6.95914 14.1714 6.75419 14.087C5.49632 13.5729 4.90958 12.1991 4.90958 10.6485C4.90958 8.09373 7.06765 5.02879 11.3436 5.02879C14.7796 5.02879 17.0422 7.51529 17.0422 10.1825C17.0422 13.7135 15.077 16.3486 12.1835 16.3486C11.211 16.3486 10.2987 15.8224 9.98526 15.2278C9.98526 15.2278 9.46283 17.3006 9.3503 17.7023C9.16142 18.3972 8.78768 19.0882 8.44608 19.6304C9.2747 19.8748 10.1341 19.9992 10.998 20C15.9692 20 20 15.971 20 11.002C20 6.03303 15.9732 2 11.002 2Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                                <a href="https://instagram.com/ydfworld?igshid=NTc4MTIwNjQ2YQ==">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M15.5 2H6.5C4.01472 2 2 4.01472 2 6.5V15.5C2 17.9853 4.01472 20 6.5 20H15.5C17.9853 20 20 17.9853 20 15.5V6.5C20 4.01472 17.9853 2 15.5 2ZM11.4556 8.14021C10.8626 8.05228 10.257 8.15356 9.72488 8.42965C9.19278 8.70575 8.7613 9.14259 8.49178 9.67805C8.22227 10.2135 8.12846 10.8203 8.2237 11.4122C8.31894 12.004 8.59837 12.5508 9.02225 12.9746C9.44613 13.3985 9.99288 13.6779 10.5847 13.7732C11.1766 13.8684 11.7834 13.7746 12.3188 13.5051C12.8543 13.2356 13.2911 12.8041 13.5672 12.272C13.8433 11.7399 13.9446 11.1343 13.8567 10.5413C13.767 9.93647 13.4851 9.3765 13.0528 8.94412C12.6204 8.51175 12.0604 8.2299 11.4556 8.14021ZM9.03402 7.09822C9.84617 6.67681 10.7705 6.52222 11.6756 6.65643C12.5988 6.79333 13.4535 7.22352 14.1134 7.88346C14.7734 8.54341 15.2036 9.3981 15.3405 10.3213C15.4747 11.2264 15.3201 12.1507 14.8987 12.9629C14.4773 13.775 13.8105 14.4336 12.9932 14.8449C12.1759 15.2563 11.2498 15.3995 10.3464 15.2541C9.44308 15.1088 8.60857 14.6823 7.96159 14.0353C7.31461 13.3883 6.88811 12.5538 6.74275 11.6505C6.59739 10.7471 6.74057 9.82094 7.15193 9.00366C7.56329 8.18638 8.22188 7.51962 9.03402 7.09822ZM15.9492 5.30078C15.535 5.30078 15.1992 5.63657 15.1992 6.05078C15.1992 6.46499 15.535 6.80078 15.9492 6.80078H15.9592C16.3734 6.80078 16.7092 6.46499 16.7092 6.05078C16.7092 5.63657 16.3734 5.30078 15.9592 5.30078H15.9492Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                                <a href="https://www.linkedin.com/company/ydf-experiential-interior-design-store/about/?viewAsMember=true">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.5604 2H3.53803C2.71719 2 2 2.59062 2 3.40183V18.4575C2 19.2732 2.71719 20 3.53803 20H18.556C19.3812 20 20 19.2683 20 18.4575V3.40183C20.0048 2.59062 19.3812 2 18.5604 2ZM7.57959 17.0039H5.00093V8.98625H7.57959V17.0039ZM6.37946 7.76723H6.36097C5.53571 7.76723 5.00133 7.1529 5.00133 6.38388C5.00133 5.6008 5.54977 5.00094 6.39352 5.00094C7.23727 5.00094 7.75356 5.59638 7.77204 6.38388C7.77164 7.1529 7.23727 7.76723 6.37946 7.76723ZM17.0039 17.0039H14.4252V12.62C14.4252 11.5697 14.0499 10.8521 13.117 10.8521C12.4042 10.8521 11.9824 11.3343 11.7947 11.804C11.7244 11.9727 11.7055 12.2025 11.7055 12.4372V17.0039H9.12686V8.98625H11.7055V10.102C12.0808 9.56763 12.667 8.79862 14.0311 8.79862C15.7238 8.79862 17.0043 9.91438 17.0043 12.3199L17.0039 17.0039Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/ydflivingconcept" className="facebook">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M20 14.769C20 18.045 18.047 19.998 14.771 19.998H13.7C13.205 19.998 12.8 19.593 12.8 19.098V13.905C12.8 13.662 12.998 13.455 13.241 13.455L14.825 13.428C14.951 13.419 15.059 13.329 15.086 13.203L15.401 11.484C15.428 11.322 15.302 11.169 15.131 11.169L13.214 11.196C12.962 11.196 12.764 10.998 12.755 10.755L12.719 8.55005C12.719 8.40605 12.836 8.28006 12.989 8.28006L15.149 8.24405C15.302 8.24405 15.419 8.12706 15.419 7.97406L15.383 5.81404C15.383 5.66104 15.266 5.54405 15.113 5.54405L12.683 5.58006C11.189 5.60706 10.001 6.83105 10.028 8.32505L10.073 10.8C10.082 11.052 9.884 11.25 9.632 11.259L8.552 11.277C8.399 11.277 8.28201 11.394 8.28201 11.547L8.30901 13.257C8.30901 13.41 8.426 13.527 8.579 13.527L9.659 13.509C9.911 13.509 10.109 13.707 10.118 13.95L10.199 19.08C10.208 19.584 9.803 19.998 9.299 19.998H7.229C3.953 19.998 2 18.045 2 14.76V7.22705C2 3.95105 3.953 1.99805 7.229 1.99805H14.771C18.047 1.99805 20 3.95105 20 7.22705V14.769Z"
                                            fill="#7F7E80"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </Mobile>
        </>
    );
};
