import React from 'react';
import { Images } from '../../assets/images';

export const SectionSix = () => {
    return (
        <section className="section-six-wrapper block block-testimonials has-bg container-fluid  nitro-offscreen">
            <div className="bg-wrap">
                <img className="bg lazyloaded lazypreload bg-wood desktopV" src={Images.bottomInfo.largeDesktop} />
                <img className="bg lazyloaded lazypreload bg-wood mobileV" src={Images.bottomInfo.phone1} />
            </div>
            <div className="action-wrapper">
                <div className="heading">our projects</div>
                <div className="text-wrapper">
                    <div className="text">RESIDENTIAL</div>
                    <div className="text-br" />
                    <div className="text ">COMMERCIAL</div>
                    <div className="text-br" />

                    <div className="text">HOSPITALITY</div>
                </div>
            </div>
        </section>
    );
};
