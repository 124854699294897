import React, { createRef, useEffect, useRef, useState } from 'react';
import { scroller, Element } from 'react-scroll';

import './styles.scss';

const Policy = ({ pageHeader, date, headerText, sectionPoints, sections }) => {
    const [activePoint, setActivePoint] = useState(0);
    const disableScrollSpy = useRef(false);

    const arrLength = sectionPoints.length;
    const elRefs = useRef([]);

    if (elRefs.current.length !== arrLength) {
        elRefs.current = Array(arrLength)
            .fill()
            .map((_, i) => elRefs.current[i] || createRef());
    }

    const scrollTo = async (name, index) => {
        setActivePoint(index);
        scroller.scrollTo(name, {
            duration: 800,
            delay: 100,
            smooth: true,
            offset: -30,
        });
        setTimeout(() => (disableScrollSpy.current = false), 1000);
    };

    const setPoint = (elem) => {
        const containerTop = elem.current.getBoundingClientRect().top;
        if (activePoint !== +elem.current.id || !+elem.current.id) {
            if (containerTop <= 30) {
                !disableScrollSpy.current && setActivePoint(+elem.current.id);
            }
        }
    };

    useEffect(() => {
        if (elRefs.current.length) {
            elRefs.current.map((elem) => window.addEventListener('scroll', () => elem.current && setPoint(elem)));
        }
        // return () => window && window.replaceWith(window.cloneNode(true));
    }, [elRefs.current]);

    return (
        <div className="policy">
            <div className="policy__header">
                <div className="policy__header_left">
                    <p className="policy__header_head">{pageHeader}</p>
                    <span className="policy__header_date">Effective Date: {date}</span>
                </div>
                <div className="policy__header_right">{headerText}</div>
            </div>
            <div className="policy__section">
                <div className="policy__section_points">
                    <ul>
                        {sectionPoints.map((point, i) => (
                            <li
                                key={point.to}
                                className={activePoint === i ? 'policy__section_point policy__section_point-active' : 'policy__section_point'}
                                onClick={() => {
                                    scrollTo(point.to, i);
                                    disableScrollSpy.current = true;
                                }}>
                                {point.title}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="policy__section_block">
                    <ul>
                        {sections.map((item, i) => (
                            <Element name={item.to} key={item.to}>
                                <li className="policy__section_item" id={i} ref={elRefs.current[i]}>
                                    <p>{item.title}</p>
                                    {item.subtitle ? <span>{item.subtitle}</span> : ''}
                                    <ul className="policy__section_item-list">
                                        {item.description &&
                                            item.description.map((elem, i) => (
                                                <li key={i} className={elem.table ? 'policy__section_item-text-table' : 'policy__section_item-text'}>
                                                    {typeof elem === 'object' ? (
                                                        elem.points ? (
                                                            <ul className="policy__section_item-bullet">
                                                                {elem.points.map((point, i) => (
                                                                    <li key={i} className="policy__section_item-text">
                                                                        {point}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : elem.table ? (
                                                            <ul className="policy__section_item-table">
                                                                <li className="policy__section_item-table-titles policy__section_item-table-titles-wide">
                                                                    <span className="policy__section_item-table-title">Data Processor</span>
                                                                    <span className="policy__section_item-table-title">What data do they receive?</span>
                                                                    <span className="policy__section_item-table-title">What do they do with it?</span>
                                                                </li>
                                                                {elem.table.map((item, i) => (
                                                                    <li key={i} className="policy__section_item-table-row">
                                                                        <div className="policy__section_item-table-titles policy__section_item-table-titles-inner">
                                                                            <div className="policy__section_item-table-header">{item.header}</div>
                                                                            <p>{item.first}</p>
                                                                            <p>{item.second}</p>
                                                                        </div>
                                                                        <div className="policy__section_item-table-titles">
                                                                            <div>
                                                                                <span className="policy__section_item-table-title">Data Processor</span>
                                                                                <div className="policy__section_item-table-header">{item.header}</div>
                                                                            </div>
                                                                            <div>
                                                                                <span className="policy__section_item-table-title">What data do they receive?</span>
                                                                                <p>{item.first}</p>
                                                                            </div>
                                                                            <div>
                                                                                <span className="policy__section_item-table-title">What do they do with it?</span>
                                                                                <p>{item.second}</p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            ''
                                                        )
                                                    ) : (
                                                        elem
                                                    )}
                                                </li>
                                            ))}
                                    </ul>
                                </li>
                            </Element>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Policy;
