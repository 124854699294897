import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Carousel } from 'antd';
import { Images } from '../../assets/images';
import { Desktop, LargeDesktop, Mobile, Tablet } from '../../responsive';
import { setImageAnimate } from '../three';

export const SectionFive = ({ onToggle }) => {
    const blockRef = useRef();
    const isAnimationFired = useRef(false);
    const [animate, setAnimate] = useState(false);
    const [resetAnimation, setResetAnimation] = useState(false);

    const [activeService, setActiveService] = useState(0);
    const [toggle1, setToggle1] = useState(true);
    const [toggle2, setToggle2] = useState(false);
    const [toggle3, setToggle3] = useState(false);
    const [toggle4, setToggle4] = useState(false);
    const [toggle5, setToggle5] = useState(false);
    const [toggle6, setToggle6] = useState(false);
    const [class1, setClass1] = useState('appear-top');
    const [class2, setClass2] = useState('appear-top');
    const [class3, setClass3] = useState('appear-top');
    const [class4, setClass4] = useState('appear-top');
    const [class5, setClass5] = useState('appear-top');
    const [class6, setClass6] = useState('appear-top');
    const [zIndex1, setZIndex1] = useState(0);
    const [zIndex2, setZIndex2] = useState(0);
    const [zIndex3, setZIndex3] = useState(0);
    const [zIndex4, setZIndex4] = useState(0);
    const [zIndex5, setZIndex5] = useState(0);
    const [zIndex6, setZIndex6] = useState(0);
    const [maxZindex, setMaxZindex] = useState(2);
    const [currentActive, setCurrentActive] = useState(12);
    const [previousActive, setPreviousActive] = useState(2);
    const image1 = React.useRef(null);
    const image2 = React.useRef(null);
    const image3 = React.useRef(null);
    const image4 = React.useRef(null);
    const image5 = React.useRef(null);
    const image6 = React.useRef(null);
    const block1 = React.useRef(null);
    const block2 = React.useRef(null);
    const block3 = React.useRef(null);
    const block4 = React.useRef(null);
    const block5 = React.useRef(null);
    const block6 = React.useRef(null);

    const image12 = React.useRef(null);
    const image22 = React.useRef(null);
    const image32 = React.useRef(null);
    const image42 = React.useRef(null);
    const image52 = React.useRef(null);
    const image62 = React.useRef(null);
    const block12 = React.useRef(null);
    const block22 = React.useRef(null);
    const block32 = React.useRef(null);
    const block42 = React.useRef(null);
    const block52 = React.useRef(null);
    const block62 = React.useRef(null);

    const image17 = React.useRef(null);
    const image27 = React.useRef(null);
    const image37 = React.useRef(null);
    const image47 = React.useRef(null);
    const image57 = React.useRef(null);
    const image67 = React.useRef(null);
    const block17 = React.useRef(null);
    const block27 = React.useRef(null);
    const block37 = React.useRef(null);
    const block47 = React.useRef(null);
    const block57 = React.useRef(null);
    const block67 = React.useRef(null);

    const image18 = React.useRef(null);
    const image28 = React.useRef(null);
    const image38 = React.useRef(null);
    const image48 = React.useRef(null);
    const image58 = React.useRef(null);
    const image68 = React.useRef(null);
    const block18 = React.useRef(null);
    const block28 = React.useRef(null);
    const block38 = React.useRef(null);
    const block48 = React.useRef(null);
    const block58 = React.useRef(null);
    const block68 = React.useRef(null);

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    useEffect(() => {
        if (blockRef.current) {
            window.addEventListener('scroll', () => !isAnimationFired.current && setImageAnimate(blockRef, isAnimationFired.current, setAnimate, 500));
        }
    }, [blockRef]);

    useEffect(() => {
        const waitForAnimation = async () => {
            if (animate) {
                await delay(150);
                setCurrentActive(window.innerWidth > 1300 ? (window.innerWidth > 1900 ? 1 : 12) : window.innerWidth < 768 ? 18 : 17);

                await delay(600);
                setResetAnimation(true);
            }
        };

        waitForAnimation();
    }, [animate]);

    React.useEffect(() => {
        eval(`block${1}.current.style.zIndex = ${maxZindex + 2}`);
        eval(`block${12}.current.style.zIndex = ${maxZindex + 2}`);
        eval(`block${18}.current.style.zIndex = ${maxZindex + 1}`);
        eval(`block${17}.current.style.zIndex = ${maxZindex + 1}`);
        eval(`image${12}.current.style.height = "720px"`);
        eval(`image${17}.current.style.height = "720px"`);

        eval(`image${1}.current.style.animation = "bottom-top 0.4s forwards"`);

        // eval(`image${12}.current.style.animation = ""`);
        eval(`image${17}.current.style.animation = ""`);
        eval(`image${18}.current.style.animation = ""`);
        eval(`image${1}.current.style.height = "720px"`);

        // eval(`image${1}.current.style.animation = "bottom-top 0.4s forwards"`);
        eval(`image${1}.current.style.animation = ""`);

        setMaxZindex(maxZindex + 1);
        setPreviousActive(currentActive);
        // setCurrentActive(12);
    }, []);

    const updateToggle = async (toUpdate, hover, mobile) => {
        console.log(toUpdate, animate, resetAnimation);
        if (toUpdate != currentActive && toUpdate - 11 != currentActive && toUpdate != currentActive - 11) {
            if (toUpdate > currentActive) {
                let currentActive1 = currentActive;
                eval(`block${toUpdate}.current.style.zIndex = ${maxZindex + 1}`);
                eval(`block${currentActive}.current.style.zIndex = ${maxZindex + 2}`);
                eval(`image${toUpdate}.current.style.height = "${mobile ? '290px' : '720px'}"`);

                eval(`image${currentActive}.current.style.animation = "${mobile ? 'bottom-top-mobile 0.7s forwards' : 'bottom-top 0.4s forwards'}"`);
                await delay(100);
                eval(`image${toUpdate}.current.children[0].style.transform = "scale(1)"`);

                setCurrentActive(toUpdate);

                await delay(300);
                eval(`image${currentActive1}.current.style.animation = ""`);
                eval(`image${currentActive1}.current.style.height = "0px"`);
                eval(`image${currentActive1}.current.children[0].style.transform = "scale(1.15)"`);

                setMaxZindex(maxZindex + 1);
                setPreviousActive(currentActive);
            } else {
                eval(`block${toUpdate}.current.style.zIndex = ${maxZindex + 1}`);

                eval(`image${toUpdate}.current.style.animation = "${mobile ? 'top-bottom-mobile 0.7s forwards' : 'top-bottom 0.4s forwards'}"`);
                await delay(100);

                eval(`image${toUpdate}.current.children[0].style.transform = "scale(1)"`);

                setPreviousActive(currentActive);
                setCurrentActive(toUpdate);

                await delay(300);
                eval(`image${toUpdate}.current.style.animation = ""`);
                eval(`image${toUpdate}.current.style.height = "${mobile ? '290px' : '720px'}"`);
                eval(`image${currentActive}.current.children[0].style.transform = "scale(1.15)"`);

                setMaxZindex(maxZindex + 1);
            }
        }

        console.log(currentActive);
    };

    const services = [
        {
            name: 'Interior Design & Architecture',
            id: 0,
        },
        {
            name: 'Fit-out & Procurement',
            id: 1,
        },
        {
            name: 'Custom Joinery',
            id: 2,
        },
        {
            name: 'Design & Build',
            id: 3,
        },
        {
            name: 'Art Consultancy',
            id: 4,
        },
        {
            name: 'Interior Styling',
            id: 5,
        },
    ];
    return (
        <section className="section-five-wrapper" ref={blockRef}>
            <LargeDesktop>
                <Row Row className="flex-center">
                    <Col span={12} className="text-col">
                        <div className="heading animated-text-wrapper mb32">
                            <span className={animate ? 'animated-text animated-text-brown-dark' : 'animated-text-disabled'}>Services</span>
                        </div>
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(1)}
                                className={`text ${(currentActive == 1 || currentActive === 12 || currentActive === 17 || currentActive === 18) && animate && resetAnimation && 'active-text'} ${
                                    !resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''
                                }`}
                                style={{ animationDelay: '.3s' }}>
                                Interior Design & Architecture
                            </div>
                        </div>
                        {/* <div data-aos="fade-up" data-aos-duration="800" data-aos-delay="20" data-aos-offset="200">
                            <div onClick={() => updateToggle(1)} className={`text mb16 ${(currentActive == 12 || currentActive == 1) && 'active-text'}`}>
                                Interior Design & Architecture
                            </div>
                        </div> */}
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(2)}
                                className={`text ${currentActive == 2 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '.45s' }}>
                                Fit-out & Procurement
                            </div>
                        </div>
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(3)}
                                className={`text ${currentActive == 3 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '.6s' }}>
                                Custom Joinery
                            </div>
                        </div>
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(4)}
                                className={`text ${currentActive == 4 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '.75s' }}>
                                Design & Build
                            </div>
                        </div>
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(5)}
                                className={`text ${currentActive == 5 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '.9s' }}>
                                Art Consultancy
                            </div>
                        </div>
                        <div className="animated-text-wrapper mb32">
                            <div
                                onClick={() => updateToggle(6)}
                                className={`text ${currentActive == 6 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '1.05s' }}>
                                Interior Styling
                            </div>
                        </div>
                        <div className="animated-text-wrapper">
                            <div onClick={() => onToggle()} className={`highlight ${animate ? 'animated-text' : 'animated-text-disabled'}`} style={{ animationDelay: '1.2s' }}>
                                Experience with us
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={{ height: '720px' }}></div>
                        <div ref={block1} className="zIndexBlock">
                            <div ref={image1} className="slider-image">
                                <img
                                    src={Images.services.desktop[0]}
                                    className={`service-desktop ${!animate ? class1 : ''}`}
                                    style={{
                                        height: animate ? '720px' : '0',
                                        bottom: !resetAnimation ? '0' : 'unset',
                                        transform: !resetAnimation ? 'scale(1.15)' : 'scale(1)',
                                        transition: animate ? '0.8s ease-out' : 'none',
                                        objectPosition: '50% 50%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block2} className="zIndexBlock">
                            <div ref={image2} className="slider-image">
                                <img
                                    src={Images.services.desktop[1]}
                                    className={`service-desktop ${class2}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 80%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block3} className="zIndexBlock">
                            <div ref={image3} className="slider-image">
                                <img
                                    src={Images.services.desktop[2]}
                                    className={`service-desktop ${class3}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 55%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block4} className="zIndexBlock">
                            <div ref={image4} className="slider-image">
                                <img
                                    src={Images.services.desktop[3]}
                                    className={`service-desktop ${class4}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 50%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block5} className="zIndexBlock">
                            <div ref={image5} className="slider-image">
                                <img
                                    src={Images.services.desktop[4]}
                                    className={`service-desktop ${class5}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 30%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block6} className="zIndexBlock">
                            <div ref={image6} className="slider-image">
                                <img
                                    src={Images.services.desktop[5]}
                                    className={`service-desktop ${class6}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 100%',
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </LargeDesktop>
            <Desktop>
                <Row Row className="flex-center">
                    <Col span={12} className="text-col">
                        <div className="heading animated-text-wrapper mb32">
                            <span className={animate ? 'animated-text animated-text-brown-dark' : 'animated-text-disabled'}>Services</span>
                        </div>
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(12)}
                                // onMouseEnter={() => updateToggle(12)}
                                // onMouseLeave={() => updateToggle(previousActive, true)}
                                className={`text ${(currentActive == 1 || currentActive === 12 || currentActive === 17 || currentActive === 18) && animate && resetAnimation && 'active-text'} ${
                                    !resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''
                                }`}
                                style={{ animationDelay: '.3s' }}>
                                Interior Design & Architecture
                            </div>
                        </div>
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(22)}
                                // onMouseEnter={() => updateToggle(22)}
                                // onMouseLeave={() => updateToggle(previousActive, true)}
                                className={`text ${currentActive == 22 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '.45s' }}>
                                Fit-out & Procurement
                            </div>
                        </div>
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(32)}
                                // onMouseEnter={() => updateToggle(32)}
                                // onMouseLeave={() => updateToggle(previousActive, true)}
                                className={`text ${currentActive == 32 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '.6s' }}>
                                Custom Joinery
                            </div>
                        </div>
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(42)}
                                // onMouseEnter={() => updateToggle(42)}
                                // onMouseLeave={() => updateToggle(previousActive, true)}
                                className={`text ${currentActive == 42 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '.75s' }}>
                                Design & Build
                            </div>
                        </div>
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(52)}
                                // onMouseEnter={() => updateToggle(52)}
                                // onMouseLeave={() => updateToggle(previousActive, true)}
                                className={`text ${currentActive == 52 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '.9s' }}>
                                Art Consultancy
                            </div>
                        </div>
                        <div className="animated-text-wrapper mb32">
                            <div
                                onClick={() => updateToggle(62)}
                                // onMouseEnter={() => updateToggle(62)}
                                // onMouseLeave={() => updateToggle(previousActive, true)}
                                className={`text ${currentActive == 62 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '1.05s' }}>
                                Interior Styling
                            </div>
                        </div>
                        <div className="animated-text-wrapper">
                            <div onClick={() => onToggle()} className={`highlight ${animate ? 'animated-text' : 'animated-text-disabled'}`} style={{ animationDelay: '1.2s' }}>
                                Experience with us
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={{ height: '720px' }}></div>
                        <div ref={block12} className="zIndexBlock">
                            <div ref={image12} className="slider-image">
                                <img
                                    src={Images.services.desktop[0]}
                                    className={`service-desktop ${!animate ? class1 : ''}`}
                                    style={{
                                        height: animate ? '720px' : '0',
                                        bottom: !resetAnimation ? '0' : 'unset',
                                        transform: !resetAnimation ? 'scale(1.15)' : 'scale(1)',
                                        transition: animate ? '0.8s ease-out' : 'none',
                                        objectPosition: '50% 40%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block22} className="zIndexBlock">
                            <div ref={image22} className="slider-image">
                                <img
                                    src={Images.services.desktop[1]}
                                    className={`service-desktop ${class2}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 100%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block32} className="zIndexBlock">
                            <div ref={image32} className="slider-image">
                                <img
                                    src={Images.services.desktop[2]}
                                    className={`service-desktop ${class3}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 67%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block42} className="zIndexBlock">
                            <div ref={image42} className="slider-image">
                                <img
                                    src={Images.services.desktop[3]}
                                    className={`service-desktop ${class4}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 50%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block52} className="zIndexBlock">
                            <div ref={image52} className="slider-image">
                                <img
                                    src={Images.services.desktop[4]}
                                    className={`service-desktop ${class5}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 35%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block62} className="zIndexBlock">
                            <div ref={image62} className="slider-image">
                                <img
                                    src={Images.services.desktop[5]}
                                    className={`service-desktop ${class6}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 100%',
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Desktop>
            <Tablet>
                <Row>
                    <Col span={12} className="text-col">
                        <div className="heading animated-text-wrapper mb32">
                            <span className={animate ? 'animated-text animated-text-brown-dark' : 'animated-text-disabled'}>Services</span>
                        </div>
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(17)}
                                className={`text ${(currentActive == 1 || currentActive === 12 || currentActive === 17 || currentActive === 18) && animate && resetAnimation && 'active-text'} ${
                                    !resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''
                                }`}
                                style={{ animationDelay: '.3s' }}>
                                Interior Design & Architecture
                            </div>
                        </div>
                        {/* <div data-aos="fade-up" data-aos-duration="800" data-aos-delay="20" data-aos-offset="200">
                            <div onClick={() => updateToggle(1)} className={`text mb16 ${(currentActive == 12 || currentActive == 1) && 'active-text'}`}>
                                Interior Design & Architecture
                            </div>
                        </div> */}
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(27)}
                                className={`text ${currentActive == 27 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '.45s' }}>
                                Fit-out & Procurement
                            </div>
                        </div>
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(37)}
                                className={`text ${currentActive == 37 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '.6s' }}>
                                Custom Joinery
                            </div>
                        </div>
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(47)}
                                className={`text ${currentActive == 47 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '.75s' }}>
                                Design & Build
                            </div>
                        </div>
                        <div className="animated-text-wrapper mb16">
                            <div
                                onClick={() => updateToggle(57)}
                                className={`text ${currentActive == 57 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '.9s' }}>
                                Art Consultancy
                            </div>
                        </div>
                        <div className="animated-text-wrapper mb32">
                            <div
                                onClick={() => updateToggle(67)}
                                className={`text ${currentActive == 67 && 'active-text'} ${!resetAnimation ? (animate ? 'animated-text animated-text-black' : 'animated-text-disabled') : ''}`}
                                style={{ animationDelay: '1.05s' }}>
                                Interior Styling
                            </div>
                        </div>
                        <div className="animated-text-wrapper">
                            <div onClick={() => onToggle()} className={`highlight ${animate ? 'animated-text' : 'animated-text-disabled'}`} style={{ animationDelay: '1.2s' }}>
                                Experience with us
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        {/* <img src={Images.services.tablet[activeService]} /> */}
                        <div style={{ height: '720px' }}></div>
                        <div ref={block17} className="zIndexBlock">
                            <div ref={image17} className="slider-image">
                                <img
                                    src={Images.services.tablet[0]}
                                    className={`service-desktop ${animate ? class1 : ''}`}
                                    style={{
                                        height: animate ? '720px' : '0px',
                                        bottom: !resetAnimation ? '0' : 'unset',
                                        transform: !resetAnimation ? 'scale(1.15)' : 'scale(1)',
                                        transition: animate ? '0.8s ease-out' : 'none',
                                        objectPosition: '50% 40%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block27} className="zIndexBlock">
                            <div ref={image27} className="slider-image">
                                <img
                                    src={Images.services.tablet[1]}
                                    className={`service-desktop ${class2}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 100%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block37} className="zIndexBlock">
                            <div ref={image37} className="slider-image">
                                <img
                                    src={Images.services.tablet[2]}
                                    className={`service-desktop ${class3}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 67%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block47} className="zIndexBlock">
                            <div ref={image47} className="slider-image">
                                <img
                                    src={Images.services.tablet[3]}
                                    className={`service-desktop ${class4}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 50%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block57} className="zIndexBlock">
                            <div ref={image57} className="slider-image">
                                <img
                                    src={Images.services.tablet[4]}
                                    className={`service-desktop ${class5}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 35%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block67} className="zIndexBlock">
                            <div ref={image67} className="slider-image">
                                <img
                                    src={Images.services.tablet[5]}
                                    className={`service-desktop ${class6}`}
                                    style={{
                                        height: '720px',
                                        objectPosition: '50% 100%',
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Tablet>
            <Mobile>
                <Row gutter={[28]}>
                    <Col span={24} style={{ height: 290 }}>
                        <div style={{ height: '290px' }}></div>
                        <div ref={block18} className="zIndexBlock" style={{ height: 290 }}>
                            <div ref={image18} className="slider-image" style={{ height: 290 }}>
                                <img
                                    src={Images.services.phone[0]}
                                    className={`service-desktop ${class1}`}
                                    style={{
                                        height: animate ? '290px' : '0px',
                                        bottom: !animate ? '0' : '0',
                                        transition: animate ? '0.4s ease-out' : 'none',
                                        objectPosition: '50% 40%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block28} className="zIndexBlock" style={{ height: 290 }}>
                            <div ref={image28} className="slider-image" style={{ height: 290 }}>
                                <img
                                    src={Images.services.phone[1]}
                                    className={`service-desktop ${class2}`}
                                    style={{
                                        // height: '720px',
                                        objectPosition: 'center 75%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block38} className="zIndexBlock" style={{ height: 290 }}>
                            <div ref={image38} className="slider-image" style={{ height: 290 }}>
                                <img
                                    src={Images.services.phone[2]}
                                    className={`service-desktop ${class3}`}
                                    style={{
                                        // height: '720px',
                                        objectPosition: 'center 65%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block48} className="zIndexBlock" style={{ height: 290 }}>
                            <div ref={image48} className="slider-image" style={{ height: 290 }}>
                                <img
                                    src={Images.services.phone[3]}
                                    className={`service-desktop ${class4}`}
                                    style={{
                                        // height: '720px',
                                        objectPosition: '50% 33%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block58} className="zIndexBlock" style={{ height: 290 }}>
                            <div ref={image58} className="slider-image" style={{ height: 290 }}>
                                <img
                                    src={Images.services.phone[4]}
                                    className={`service-desktop ${class5}`}
                                    style={{
                                        // height: '720px',
                                        objectPosition: '50% 20%',
                                    }}
                                />
                            </div>
                        </div>
                        <div ref={block68} className="zIndexBlock" style={{ height: 290 }}>
                            <div ref={image68} className="slider-image" style={{ height: 290 }}>
                                <img
                                    src={Images.services.phone[5]}
                                    className={`service-desktop ${class6}`}
                                    style={{
                                        // height: '720px',
                                        objectPosition: 'center 95%',
                                    }}
                                />
                            </div>
                        </div>
                        {/* <img
                            style={{
                                objectPosition: `${
                                    activeService == 0
                                        ? '50% 40%'
                                        : activeService == 1
                                        ? 'center 75%'
                                        : activeService == 2
                                        ? 'center 65%'
                                        : activeService == 3
                                        ? '50% 33%'
                                        : activeService == 4
                                        ? '50% 20%'
                                        : activeService == 5
                                        ? 'center 95%'
                                        : 'center'
                                }`,
                            }}
                            src={Images.services.phone[activeService]}
                        /> */}
                    </Col>
                    <Col span={24} className="text-col">
                        <div className="heading mb32">Services</div>
                        {/* {services.map(({ name, id }) => (
                            <div onClick={() => setActiveService(id)} className={`text ${id === activeService ? 'active-text' : ''} ${id === 5 ? 'mb32' : 'mb16'}`}>
                                {name}
                            </div>
                        ))} */}
                        {console.log(currentActive)}
                        <div onClick={() => updateToggle(18, undefined, true)} className={`text mb16 ${(currentActive == 11 || currentActive === 12 || currentActive === 17 || currentActive === 18) && 'active-text'}`}>
                            Interior Design & Architecture
                        </div>
                        <div onClick={() => updateToggle(28, undefined, true)} className={`text mb16 ${currentActive == 28 && 'active-text'}`}>
                            Fit-out & Procurement
                        </div>
                        <div onClick={() => updateToggle(38, undefined, true)} className={`text mb16 ${currentActive == 38 && 'active-text'}`}>
                            Custom Joinery
                        </div>
                        <div onClick={() => updateToggle(48, undefined, true)} className={`text mb16 ${currentActive == 48 && 'active-text'}`}>
                            Design & Build
                        </div>
                        <div onClick={() => updateToggle(58, undefined, true)} className={`text mb16 ${currentActive == 58 && 'active-text'}`}>
                            Art Consultancy
                        </div>
                        <div onClick={() => updateToggle(68, undefined, true)} className={`text mb32 ${currentActive == 68 && 'active-text'}`}>
                            Interior Styling
                        </div>
                        <div className="highlight highlight_mobile" onClick={() => onToggle()}>
                            Experience with us
                        </div>
                    </Col>
                </Row>
            </Mobile>
        </section>
    );
};
