import React from 'react';
import { Desktop, LargeDesktop, Mobile, Tablet } from '../../responsive';
import { Images } from '../../assets/images';
import './style.scss';
import Header from '../../components/header';

export const Hero = ({ orange }) => {
    return (
        <div className={orange ? 'main-section main-section-orange' : 'main-section'}>
            {!orange ? <div className="graaa"></div> : ''}
            <Header orange={orange} />

            {!orange ? (
                <>
                    <LargeDesktop>
                        <img src={Images.banner.largeDesktopBanner} />
                    </LargeDesktop>
                    <Desktop>
                        <img src={Images.banner.desktopBanner} />
                    </Desktop>
                    <Tablet>
                        <img src={Images.banner.tablet} />
                    </Tablet>
                    <Mobile>
                        <img src={Images.banner.mobile} />
                    </Mobile>
                </>
            ) : (
                ''
            )}
        </div>
    );
};
