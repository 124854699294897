import React, { useEffect, useRef, useState } from 'react';
import { setImageAnimate } from '../three';

export const SectionEight = ({ onToggleB }) => {
    const blockRef = useRef();
    const isAnimationFired = useRef(false);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (blockRef.current) {
            window.addEventListener('scroll', () => !isAnimationFired.current && setImageAnimate(blockRef, isAnimationFired.current, setAnimate, 400));
        }
    }, [blockRef]);

    return (
        <section className="section-eight-wrapper" ref={blockRef}>
            <div className="heading mb8 animated-text-wrapper">
                <span className={animate ? 'animated-text' : 'animated-text-disabled'}>CREATE WITH US</span>
            </div>
            <div className="support mb64 animated-text-wrapper">
                <span className={animate ? 'animated-text animated-text-default' : 'animated-text-disabled'}>We are always on the lookout for top talent to join us.</span>
            </div>
            <div className="text-active mb16 animated-text-wrapper">
                <span className={animate ? 'animated-text' : 'animated-text-disabled'} style={{ animationDelay: '.25s' }}>
                    Open Positions
                </span>
            </div>
            <div className="text mb16 animated-text-wrapper">
                <span className={animate ? 'animated-text animated-text-default' : 'animated-text-disabled'} style={{ animationDelay: '.4s' }}>
                    Furniture Sales Manager
                </span>
            </div>
            <div className="text mb16 animated-text-wrapper">
                <span className={animate ? 'animated-text animated-text-default' : 'animated-text-disabled'} style={{ animationDelay: '.55s' }}>
                    Kitchens & Joinery Sales Manager
                </span>
            </div>
            <div className="text mb16 animated-text-wrapper">
                <span className={animate ? 'animated-text animated-text-default' : 'animated-text-disabled'} style={{ animationDelay: '.7s' }}>
                    Lighting Sales Manager
                </span>
            </div>
            <div className="text mb16 animated-text-wrapper">
                <span className={animate ? 'animated-text animated-text-default' : 'animated-text-disabled'} style={{ animationDelay: '.85s' }}>
                    Design Manager Textiles
                </span>
            </div>
            <div className="text mb30 animated-text-wrapper">
                <span className={animate ? 'animated-text animated-text-default' : 'animated-text-disabled'} style={{ animationDelay: '1s' }}>
                    Home Decor Manager
                </span>
            </div>
            <div className="animated-text-wrapper" style={{width: 70, marginLeft: 'auto', marginRight: 'auto'}}>
                <div 
                    onClick={
                        () => onToggleB()
                    }
                    className={`highlight ${animate ? 'animated-text' : 'animated-text-disabled'}`} style={{ animationDelay: '1.15s' }}
                >
                        Join us
                </div>
            </div>
        </section>
    );
};
