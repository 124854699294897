import React from "react";
import { Row, Col } from "antd";
import { SolidButton } from "../../components/button";

export const SectionNine = ({ onToggle, onToggleB }) => {
  return (
    <section className="section-nine-wrapper">
      <Row>
        <Col span={24} className="mobile-padding">
          <div className="heading">ENQUIRE</div>
          <div className="text">
            In case you are interested in discussing an interior design project
            or learning more about YDF, please do not hesitate to contact us.
          </div>
          <button type="submit" className={`custom-buttom`} onClick={onToggle} style={{maxWidth: `250px !important`, maxHeight: `45px !important`}}>
          MAKE AN ENQUIRY
          </button>
          {/* <SolidButton text="MAKE AN ENQUIRY" onClick={onToggle} width={250} height={45} extraPadding={true}/> */}
        </Col>
      </Row>
    </section>
  );
};
