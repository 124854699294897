import React, { useEffect, useRef, useState } from 'react';
import { Images } from '../../assets/images';
import { Desktop, LargeDesktop, Mobile, Tablet } from '../../responsive';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, A11y, EffectFade, Pagination, EffectCreative, Controller } from 'swiper/modules';
import 'swiper/css';
import Carousel from '../../components/carousel';
import SwiperButtonNext from '../../components/nextSlideButton';
import SwiperButtonPrev from '../../components/prevSlideButton';
import { setImageAnimate } from '../three';

export const SectionSeven = () => {
    const blockRef = useRef();
    const isAnimationFired = useRef(false);
    const [animate, setAnimate] = useState(false);

    const [swiper, setSwiper] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return `<span className="swiper-pagination-bullet ${swiper && index === swiper.realIndex ? 'swiper-pagination-bullet-active' : ''}"><span></span></span>`;
        },
    };

    const nextSlide = () => {
        swiper.slideNext();
    };

    const prevSlide = () => {
        swiper.slidePrev();
    };

    useEffect(() => {
        if (blockRef.current) {
            window.addEventListener('scroll', () => !isAnimationFired.current && setImageAnimate(blockRef, isAnimationFired.current, setAnimate, 500));
        }
    }, [blockRef]);

    return (
        <section className="section-seven-wrapper" ref={blockRef}>
            <div style={{ height: '29px', background: 'white', width: '100vw' }}></div>
            <LargeDesktop>
                <div className="hover-show-controls">
                    <Carousel images={Images.bottomSlider.largeDesktop.map((src) => src)} animate={animate} />
                </div>
            </LargeDesktop>
            <Desktop>
                <div className="hover-show-controls">
                    <Carousel images={Images.bottomSlider.desktop.map((src) => src)} animate={animate} />
                </div>
            </Desktop>
            <Tablet>
                <div className="hover-show-controls">
                    <Carousel images={Images.bottomSlider.tablet.map((src) => src)} animate={animate} />
                </div>
            </Tablet>
            <Mobile>
                <Carousel images={Images.bottomSlider.phone.map((src) => src)} animate={animate} />
            </Mobile>
        </section>
    );
};
