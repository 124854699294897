import React, { useState } from 'react';
import { Checkbox, Col, Modal, Row, message as messages, Form, Input } from 'antd';
import { SolidButton, SolidButtonForm } from './button';
import { Desktop, LargeDesktop, Mobile, Tablet } from '../responsive';
import { Link } from 'react-router-dom';

// const host = "http://localhost:1337/";
const Item = Form.Item;
const host = 'https://ydf-strapi-9534c0d766db.herokuapp.com/';

export const EnquireDrawer = ({ open, onClose, title }) => {
    const [form] = Form.useForm();

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [message, setMessage] = useState();
    const [policy, setPolicy] = useState();
    const [news, setNews] = useState();

    const reset = () => {
        setEmail('');
        setFirstName('');
        setLastName('');
        setMessage('');
        setPhoneNumber('');
        setNews(false);
        setPolicy(false);
        form.resetFields();
    };

    const onSubmit = async (values) => {
        form.validateFields().then(() => {
            fetch(`${host}api/enquiries`, {
                method: 'POST',
                body: JSON.stringify({
                    data: values,
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then((response) => response.json())
                .then(() => {
                    messages.success('Enquiry sent successfully.');
                    reset();
                    onClose();
                });
        });
    };
    const firstNameRef = React.useRef();
    const lastNameRef = React.useRef();
    const emailRef = React.useRef();
    const phoneRef = React.useRef();
    const messageRef = React.useRef();
    const textAreaRefValue = React.useRef();

    const span1Ref = React.useRef();
    const span2Ref = React.useRef();
    const span3Ref = React.useRef();
    const span4Ref = React.useRef();
    const span5Ref = React.useRef();
    const span6Ref = React.useRef();

    // const firstNameRef = React.useRef();

    const handleFocus = (elem, span) => {
        elem.current.style.fontSize = '11px';
        elem.current.style.letterSpacing = '0.77px';
        span.current.style.background = '#686868';
        span.current.style.opacity = '1';
        span.current.style.width = '100%';
    };

    const handleBlur = (elem, span) => {
        elem.current.style.fontSize = '14px';
        elem.current.style.letterSpacing = '0.98px';
        span.current.style.background = '#C0BEBE';
        span.current.style.opacity = '0';
        span.current.style.width = '0px';
    };

    let prevRows = 0;

    React.useEffect(() => {
        const handleInput = () => {
            const textarea = textAreaRefValue.current?.resizableTextArea?.textArea;
            const currentRowCount = textarea.value.split('\n').length;
        };

        textAreaRefValue.current?.resizableTextArea?.textArea.addEventListener('input', handleInput);

        return () => {
            textAreaRefValue.current?.resizableTextArea?.textArea.removeEventListener('input', handleInput);
        };
    }, []);

    return (
        <Modal
            footer={null}
            title={null}
            closable={false}
            open={open}
            className="enquire-drawer-wrapper"
            rootClassName="test"
            maskClosable={true}
            width={'100%'}
            style={{
                top: 0,
                height: '100%',
                padding: 0,
                width: '100%',
                maxWidth: '959px',
            }}
            onCancel={() => {
                reset();
                onClose();
            }}>
            <div className="enquire-wrapper">
                <div className="close-wrapper">
                    <div
                        className="close"
                        onClick={() => {
                            reset();
                            onClose();
                        }}>
                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25.7272 47.3958C37.6934 47.3958 47.3939 37.6953 47.3939 25.7292C47.3939 13.763 37.6934 4.0625 25.7272 4.0625C13.761 4.0625 4.06055 13.763 4.06055 25.7292C4.06055 37.6953 13.761 47.3958 25.7272 47.3958Z"
                                fill="#804E3B"
                            />
                            <path d="M33.3125 18.6875L18.6875 33.3125" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M18.6875 18.6875L33.3125 33.3125" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
                <div className="heading">{title || 'ENQUIRE'}</div>
                <div className="text" style={{ marginBottom: '60px !important' }}>
                    In case you are interested in discussing an interior design project or learning more about YDF, please do not hesitate to contact us.
                </div>
                <Form form={form} layout="vertical" onFinish={onSubmit}>
                    <div className="action-wrapper">
                        <Row gutter={[32]} className="super-width">
                            <Col xl={12} lg={12} md={12} xs={24}>
                                <label htmlFor="firstName" ref={firstNameRef}>
                                    First Name
                                </label>

                                <div className="cs-input-2">
                                    <Item rules={[{ required: true, message: 'First name is required.' }]} name={'firstName'} onFocus={() => handleFocus(firstNameRef, span1Ref)} onBlur={() => handleBlur(firstNameRef, span1Ref)}>
                                        <Input />
                                    </Item>
                                    <span className="static-span-form"></span>
                                    <span ref={span1Ref}></span>
                                </div>
                            </Col>
                            <Col xl={12} lg={12} md={12} xs={24}>
                                <label htmlFor="lastName" ref={lastNameRef}>
                                    Last Name
                                </label>
                                <div className="cs-input-2">
                                    <Item rules={[{ required: true, message: 'Last name is required.' }]} name={'lastName'} onFocus={() => handleFocus(lastNameRef, span2Ref)} onBlur={() => handleBlur(lastNameRef, span2Ref)}>
                                        <Input />
                                    </Item>
                                    <span className="static-span-form"></span>
                                    <span ref={span2Ref}></span>
                                </div>
                            </Col>
                            <Col xl={12} lg={12} md={12} xs={24}>
                                <label htmlFor="email" ref={emailRef}>
                                    Email
                                </label>
                                <div className="cs-input-2">
                                    <Item
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Email is required.',
                                            },
                                            {
                                                type: 'email',
                                                message: 'Email is not valid.',
                                            },
                                        ]}
                                        name={'email'}
                                        onFocus={() => handleFocus(emailRef, span3Ref)}
                                        onBlur={() => handleBlur(emailRef, span3Ref)}>
                                        <Input type="email" onChange={setEmail} />
                                    </Item>
                                    <span className="static-span-form"></span>
                                    <span ref={span3Ref}></span>
                                </div>
                            </Col>
                            <Col xl={12} lg={12} md={12} xs={24}>
                                <label htmlFor="phoneNumber" ref={phoneRef}>
                                    Phone Number
                                </label>
                                <div className="cs-input-2">
                                    <Item rules={[{ required: true, message: 'Phone number is required.' }]} name={'phoneNumber'} onFocus={() => handleFocus(phoneRef, span4Ref)} onBlur={() => handleBlur(phoneRef, span4Ref)}>
                                        <Input />
                                    </Item>
                                    <span className="static-span-form"></span>
                                    <span ref={span4Ref}></span>
                                </div>
                            </Col>
                            <Col xl={24} lg={24} md={24} xs={24}>
                                <label htmlFor="message" ref={messageRef}>
                                    Message
                                </label>
                                <div className="cs-input-2">
                                    <Item rules={[{ required: true, message: 'Message is required.' }]} name={'message'} onFocus={() => handleFocus(messageRef, span5Ref)} onBlur={() => handleBlur(messageRef, span5Ref)}>
                                        <Input.TextArea ref={textAreaRefValue} rows={1} autoSize={true} />
                                    </Item>
                                    <span className="static-span-form-textarea"></span>
                                    <span ref={span5Ref} className="static-span-form-textarea-2"></span>
                                </div>
                            </Col>
                            <div className="margin-top-bottom-60">
                                <Col span={24}>
                                    <Checkbox checked={news} onChange={(e) => setNews(e.target.checked)} className="mont-font">
                                        Join our Newsletter
                                    </Checkbox>
                                    <Item
                                        name={'policy'}
                                        rules={[
                                            {
                                                validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Privacy policy acceptance is required.'))),
                                            },
                                            // {
                                            //     required: true,
                                            //     message: 'Privacy policy acceptance is required.',
                                            // },
                                        ]}
                                        valuePropName="checked"
                                        style={{
                                            marginTop: '10px',
                                        }}>
                                        <Checkbox checked={policy} onChange={(e) => setPolicy(e.target.checked)} className="mont-font">
                                            I’ve read and accept the{' '}
                                            <Link
                                                to="/privacy"
                                                className="text-under"
                                                onClick={() => {
                                                    reset();
                                                    onClose();
                                                }}>
                                                privacy policy
                                            </Link>
                                            .
                                        </Checkbox>
                                    </Item>
                                </Col>
                            </div>
                        </Row>
                    </div>
                    <div className="fixed-mobile">
                        <SolidButtonForm type="submit" text="submit" />
                    </div>
                </Form>
            </div>
        </Modal>
    );
};
