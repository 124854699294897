import React from "react";

export const Input = ({ value, text, onChnage }) => {

  const titleRef = React.useRef();

  return (
    <div className="cs-input">
      <div className="title" ref={titleRef}>{text}</div>
      <input value={value} 
      onFocus={(e) => {
        titleRef.current.style.fontSize = "11px"
        titleRef.current.style.letterSpacing = "0.77px"
      }} 
      onBlur={
        (e) => {
          titleRef.current.style.fontSize = "14px"
          titleRef.current.style.letterSpacing = "0.98px"
        }} onChange={(e) => onChnage(e.target.value)} />
      <span className='static-span'></span>
      <span></span>

    </div>
  );
};
